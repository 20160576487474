import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%", // Fix IE 11 to prevent content overlapping
    },
    picture: {
      maxWidth: "52rem",
      paddingBottom: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    content: {
      width: "100%", // Fix IE 11 to prevent content overlapping
    },
    actions: {
      marginTop: theme.spacing(4),
    },
  }),
  { name: "Intro" }
);
