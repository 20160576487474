import { Box, Typography } from "@material-ui/core";

export const TitleField = (props) => {
  const { title } = props;

  return (
    <Box mb={1} mt={1}>
      <Typography variant="h2">{title}</Typography>
    </Box>
  );
};
