import { Button, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as IntroImage } from "../../assets/images/intro.svg";

import { useStyles } from "./StepIntro.style.js";

export const StepIntro = (props) => {
  const { onSubmit } = props;
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.picture}>
        <IntroImage title={intl.formatMessage({ id: "360.intro.titre" })} />
      </div>
      <div className={classes.title}>
        <Typography align="center" variant="h1">
          <FormattedMessage id="360.intro.titre" />
        </Typography>
      </div>
      <div className={classes.content}>
        <Typography align="center" variant="subtitle1">
          <FormattedMessage id="360.intro.soustitre1" />
          <br />
          <FormattedMessage id="360.intro.soustitre2" />
        </Typography>
      </div>
      <div className={classes.actions}>
        <Button
          color="primary"
          onClick={onSubmit}
          size="large"
          variant="contained"
        >
          <FormattedMessage id="360.intro.cta.suivant" />
        </Button>
      </div>
    </div>
  );
};

StepIntro.propTypes = {
  onSubmit: PropTypes.func,
};
