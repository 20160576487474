import { palette } from "../palette";

export const MuiInput = {
  overrides: {
    formControl: {
      //"label + &": { marginTop: 0 },
    },
    input: {
      padding: "10px 15px",
      "&$disabled": {
        cursor: "not-allowed",
      },
      "&:invalid, &::placeholder": {
        color: palette.text.light,
        opacity: 1,
      },
      "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus": {
        "-webkit-box-shadow": "0 0 0px 1000px #fff inset",
      },
    },
    underline: {
      "&$disabled:before": {
        borderBottomStyle: "solid",
        borderBottomColor: palette.text.disabled,
      },
      "&:before": { borderBottomWidth: "2px" },
    },
  },
};
