import { useRadioGroup } from "@material-ui/core";
import cx from "classnames";

import { createChainedFunction } from "../../utils/functions";

import { useStyles } from "./RadioCard.style";

export const RadioCard = (props) => {
  const {
    checked: checkedProp,
    children,
    classes: classesProp,
    disabled,
    inputRef,
    name: nameProp,
    onChange: onChangeProp,
    value,
    ...other
  } = props;
  const radioGroup = useRadioGroup();
  const classes = useStyles({ classes: classesProp });

  let checked = checkedProp;
  const onChange = createChainedFunction(
    onChangeProp,
    radioGroup && radioGroup.onChange
  );
  let name = nameProp;

  if (radioGroup) {
    if (typeof checked === "undefined") {
      checked = radioGroup.value === props.value;
    }
    if (typeof name === "undefined") {
      name = radioGroup.name;
    }
  }

  return (
    <label
      className={cx(classes.root, {
        [classes.disabled]: disabled,
        [classes.checked]: checked,
      })}
    >
      {children}
      <input
        {...other}
        checked={checkedProp}
        className={classes.input}
        disabled={disabled}
        name={name}
        onChange={onChange}
        ref={inputRef}
        type="radio"
        value={value}
      />
    </label>
  );
};
