import { darken, alpha } from "@material-ui/core";

const BASE_COLOR = "#162a35";
const BASE_COLOR_2 = "#2d3f49";
const ERROR_COLOR = "#ed365a";
const PRIMARY_COLOR = "#1f2663";
const SECONDARY_COLOR = "#f29133";
const SUCCESS_COLOR = "#68d391";

export const palette = {
  backgroundColor: { default: "#fff" },
  blue: {
    100: "#f4f4f7",
    200: "#727798",
  },
  error: {
    main: ERROR_COLOR,
  },
  grey: {
    300: "#e7e9ea",
  },
  primary: {
    // light: will be calculated from palette.primary.main,
    main: PRIMARY_COLOR,
    // dark: will be calculated from palette.primary.main,
    contrastText: "#fff",
  },
  secondary: {
    // light: will be calculated from palette.secondary.main,
    main: SECONDARY_COLOR,
    dark: darken(SECONDARY_COLOR, 0.07),
    contrastText: "#fff",
  },
  success: {
    main: SUCCESS_COLOR,
  },
  text: {
    disabled: alpha(BASE_COLOR_2, 0.3),
    light: alpha(BASE_COLOR_2, 0.6),
    primary: BASE_COLOR,
  },
};
