import { Avatar, Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as Check } from "../../../assets/images/check.svg";
import { ReactComponent as SignatureConfirmationImage } from "../../../assets/images/introPrivateEquity.svg";
import { StepBar } from "../../../components/stepper/StepBar";
import { StepperTitle } from "../../../components/stepper/StepperTitle";
import { steps } from "../steps/stepsDefinition";

import { useStyles } from "./Eligibility.style";

export const SignatureConfirmation = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: 1,
      }}
    >
      <Box sx={{ gridRow: "1" }}>
        <StepperTitle
          count={Object.keys(steps).length}
          current={Object.keys(steps).length}
          displayCurrentStep={false}
          label={intl.formatMessage({
            id: `privateEquity.result.title`,
          })}
          title={intl.formatMessage({ id: "privateEquity.title" })}
        />
        <StepBar
          count={Object.keys(steps).length}
          current={Object.keys(steps).length - 1}
        />
      </Box>
      <Box sx={{ gridRow: "2" }}>
        <Grid container className={classes.greyPanel}>
          <Grid item xs={12} sm={1}>
            <Avatar
              style={{ height: 36, width: 36 }}
              className={classes.greenBulletPoint}
            >
              <Check alt={""} />
            </Avatar>
          </Grid>
          <Grid item xs={12} sm={11}>
            <Typography align="left" className={classes.bigTitle}>
              <FormattedMessage id="privateEquity.signature.confirmation.title" />
            </Typography>
            <Grid container className={classes.subSection}>
              <Grid item xs={12} sm={8}>
                <Typography align="left" className={classes.bigContent}>
                  <FormattedMessage id="privateEquity.signature.confirmation.text1" />
                </Typography>
                <Typography align="left" className={classes.bigContent}>
                  <FormattedMessage id="privateEquity.signature.confirmation.text2" />
                </Typography>
                <Box mt={2} pt={3}>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    target={"_self"}
                    href="https://www.yomoni.fr"
                    title={intl.formatMessage({
                      id: "privateEquity.signature.confirmation.backToYomoni",
                    })}
                  >
                    <FormattedMessage id="privateEquity.signature.confirmation.backToYomoni" />
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box spacing={2} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                  }}
                  className={classes.seeingLongTerm}
                >
                  <SignatureConfirmationImage
                    title={intl.formatMessage({
                      id: "privateEquity.signature.confirmation.title",
                    })}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
