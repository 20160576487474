import { FormLabel, Slider } from "@material-ui/core";
import { utils } from "@rjsf/core";
import cx from "classnames";

import { useStyles } from "./RangeWidget.style";

const { rangeSpec } = utils;

export const RangeWidget = (props) => {
  const {
    value,
    readonly,
    disabled,
    onBlur,
    onFocus,
    options = {},
    schema,
    onChange,
    required,
    label,
    id,
  } = props;
  const sliderProps = { value, label, id, ...rangeSpec(schema) };
  // Omit `emptyValue` and `enumOptions` from options and pass other to widget
  const {
    emptyValue,
    enumOptions,
    valueLabelDisplay,
    ...otherOptions
  } = options;
  const classes = useStyles();

  const _onChange = (event, value) =>
    onChange(value === "" ? options.emptyValue : value);
  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  const _onFocus = ({ target: { value } }) => onFocus(id, value);

  return (
    <>
      <FormLabel required={required} id={id}>
        {label}
      </FormLabel>
      <Slider
        {...otherOptions}
        className={cx({
          [classes.visibleLabelSlider]: valueLabelDisplay === "on",
        })}
        disabled={disabled || readonly}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        valueLabelDisplay={valueLabelDisplay}
        {...sliderProps}
      />
    </>
  );
};
