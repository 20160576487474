import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Form } from "../../components/form/Form";

const EMAIL_REGEXP = new RegExp(
  "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$"
);

const getSchema = (values, intl) => {
  const schema = {
    type: "object",
    required: ["email"],
    properties: {
      email: {
        type: "string",
        title: intl.formatMessage({ id: "360.etape7.email" }),
      },
    },
  };

  return schema;
};

export const Step7 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  const uiSchema = useMemo(
    () => ({
      email: {
        "ui:widget": "email",
        "ui:options": {
          helperText: intl.formatMessage({
            id: "360.etape7.email.description",
          }),
        },
      },
    }),
    [intl]
  );

  const validate = (formData, errors) => {
    if (formData.email && !EMAIL_REGEXP.test(formData.email)) {
      errors.email.addError(intl.formatMessage({ id: "error.invalid" }));
    }
    return errors;
  };

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        onSubmitLabel={intl.formatMessage({ id: "cta.valider" })}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
        validate={validate}
      />
    </Slide>
  );
};

Step7.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
