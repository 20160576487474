import API from "@aws-amplify/api";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { UniversignSignatureBase } from "../../../components/signature/UniversignSignature";
import { StepBar } from "../../../components/stepper/StepBar";
import { StepperTitle } from "../../../components/stepper/StepperTitle";
import { trackSignature } from "../../../redux/cdp/actions";
import { steps } from "../steps/stepsDefinition";

import { useStyles } from "./Eligibility.style";

const SignatureState = {
  NONE: "NONE",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const Signature = () => {
  const [signatureUrl, setSignatureUrl] = useState("");
  const [signatureState, setSignatureState] = useState(SignatureState.NONE);
  const history = useHistory();
  const { privateEquityIdOrSignatureUrl } = useParams();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();

  const handleSignSuccess = () => {
    setSignatureState(SignatureState.SUCCESS);
    dispatch(
      trackSignature({
        signatureUrl: signatureUrl,
        state: true,
      })
    );
    history.push(`${url}/confirmation`);
  };

  const handleSignCancel = () => {
    setSignatureState(SignatureState.NONE);
    history.push(`https://mon-espace.yomoni.fr/dashboard`);
  };

  const handleSignError = () => {
    setSignatureState(SignatureState.ERROR);
    history.push(`${url}/eligible/${privateEquityIdOrSignatureUrl}`);
  };

  const handleCloseDialog = () => {
    setSignatureState(SignatureState.NONE);
  };

  const getSignatureUrlFromSalesforce = () => {
    API.get(
      "PrivateEquity",
      "/private-equity/" + privateEquityIdOrSignatureUrl + "/signatureUrl"
    )
      .then((result) => {
        let signatureUrl = result.signatureUrl;
        if (signatureUrl) {
          setSignatureUrl(signatureUrl);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    try {
      const signatureUrl = decodeURIComponent(
        atob(privateEquityIdOrSignatureUrl)
      );
      if (
        signatureUrl.startsWith("https") &&
        signatureUrl.includes("universign")
      ) {
        setSignatureUrl(signatureUrl);
      } else {
        getSignatureUrlFromSalesforce();
      }
    } catch (e) {
      getSignatureUrlFromSalesforce();
    }
    // eslint-disable-next-line
  }, [privateEquityIdOrSignatureUrl]);

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: 1,
      }}
      style={{ width: "100%" }}
    >
      <Box sx={{ gridRow: "1" }}>
        <StepperTitle
          count={Object.keys(steps).length}
          current={Object.keys(steps).length}
          displayCurrentStep={false}
          label={intl.formatMessage({ id: `privateEquity.signature.title` })}
          title={intl.formatMessage({ id: "privateEquity.title" })}
        />
        <StepBar
          count={Object.keys(steps).length}
          current={Object.keys(steps).length - 1}
        />
      </Box>
      <Box sx={{ gridRow: "2" }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="stretch"
          className={classes.signatureSection}
        >
          {signatureUrl ? (
            <UniversignSignatureBase
              onCancel={handleSignCancel}
              onError={handleSignError}
              onSuccess={handleSignSuccess}
              url={signatureUrl}
            />
          ) : (
            <Typography align="center" variant="h1">
              <FormattedMessage id="privateEquity.signature.loading" />
            </Typography>
          )}
        </Grid>
      </Box>
      <Dialog
        aria-labelledby="dialog-erreur-formulaire"
        onClose={handleCloseDialog}
        open={signatureState === SignatureState.ERROR}
      >
        <DialogContent dividers id="signature-dialogError">
          <Typography align="center">
            <WarningRounded color="error" fontSize="large" />
          </Typography>
          <Typography align="center" gutterBottom variant="h1">
            <FormattedMessage id="privateEquity.signature.dialogError.title" />
          </Typography>
          <Typography align="center">
            <FormattedMessage id="privateEquity.signature.dialogError.text1" />
          </Typography>
          <Typography align="center">
            <FormattedMessage id="privateEquity.signature.dialogError.text2" />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            <FormattedMessage id="privateEquity.signature.dialogError.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
