/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "Cdp",
            "endpoint": "https://uf5zzyb8f4.execute-api.eu-central-1.amazonaws.com/master",
            "region": "eu-central-1"
        },
        {
            "name": "Opportunites",
            "endpoint": "https://uv2h9pmz77.execute-api.eu-central-1.amazonaws.com/master",
            "region": "eu-central-1"
        },
        {
            "name": "PrivateEquity",
            "endpoint": "https://y4uo1lx4lc.execute-api.eu-central-1.amazonaws.com/master",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
