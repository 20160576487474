import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Form } from "../../components/form/Form";
import { createNumberOptions } from "../../utils/form";

const getSchema = (values, intl) => {
  const schema = {
    type: "object",
    required: ["caracteristiquesPlacement"],
    properties: {
      changementSituation: {
        type: "object",
        title: intl.formatMessage({ id: "360.etape6.changementSituation" }),
        required: [],
        properties: {
          courtTerme: {
            type: "string",
            pattern: "^[^<>{}[\\]]*$",
            maxLength: 1000,
            title: intl.formatMessage({
              id: "360.etape6.changementSituation.courtTerme",
            }),
          },
          montantCourtTerme: {
            type: "number",
            title: intl.formatMessage({
              id: "360.etape6.changementSituation.montant",
            }),
          },
          moyenTerme: {
            type: "string",
            pattern: "^[^<>{}[\\]]*$",
            maxLength: 1000,
            title: intl.formatMessage({
              id: "360.etape6.changementSituation.moyenTerme",
            }),
          },
          montantMoyenTerme: {
            type: "number",
            title: intl.formatMessage({
              id: "360.etape6.changementSituation.montant",
            }),
          },
          longTerme: {
            type: "string",
            pattern: "^[^<>{}[\\]]*$",
            maxLength: 1000,
            title: intl.formatMessage({
              id: "360.etape6.changementSituation.longTerme",
            }),
          },
          montantLongTerme: {
            type: "number",
            title: intl.formatMessage({
              id: "360.etape6.changementSituation.montant",
            }),
          },
        },
      },
      caracteristiquesPlacement: {
        type: "array",
        title: intl.formatMessage({
          id: "360.etape6.caracteristiquesPlacement",
        }),
        default: [
          "securite",
          "rentabilite",
          "liquiditeDisponibilite",
          "fiscaliteEntree",
          "fiscaliteSortie",
          "gestionDeleguee",
        ],
        items: {
          type: "string",
          anyOf: [
            {
              title: intl.formatMessage({
                id: "caracteristiquePlacement.securite",
              }),
              const: "securite",
            },
            {
              title: intl.formatMessage({
                id: "caracteristiquePlacement.rentabilite",
              }),
              const: "rentabilite",
            },
            {
              title: intl.formatMessage({
                id: "caracteristiquePlacement.liquiditeDisponibilite",
              }),
              const: "liquiditeDisponibilite",
            },
            {
              title: intl.formatMessage({
                id: "caracteristiquePlacement.fiscaliteEntree",
              }),
              const: "fiscaliteEntree",
            },
            {
              title: intl.formatMessage({
                id: "caracteristiquePlacement.fiscaliteSortie",
              }),
              const: "fiscaliteSortie",
            },
            {
              title: intl.formatMessage({
                id: "caracteristiquePlacement.gestionDeleguee",
              }),
              const: "gestionDeleguee",
            },
          ],
        },
      },
    },
  };

  return schema;
};

const uiSchema = {
  "ui:order": ["changementSituation", "caracteristiquesPlacement"],
  changementSituation: {
    courtTerme: { "ui:widget": "textarea" },
    montantCourtTerme: createNumberOptions({ adornment: "€" }),
    moyenTerme: { "ui:widget": "textarea" },
    montantMoyenTerme: createNumberOptions({ adornment: "€" }),
    longTerme: { "ui:widget": "textarea" },
    montantLongTerme: createNumberOptions({ adornment: "€" }),
  },
  caracteristiquesPlacement: {
    "ui:options": {
      addable: false,
      removable: false,
    },
    items: {
      "ui:widget": (props) => {
        const { id, options = {}, value } = props;
        const index = Number(id.slice(-1)) + 1;
        const { enumOptions = [] } = options;
        const currentOption = enumOptions.find(
          (enumOption) => enumOption.value === value
        );
        const label = currentOption ? currentOption.label : value;
        return (
          <span>
            {index} - {label}
          </span>
        );
      },
    },
  },
};

export const Step6 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
      />
    </Slide>
  );
};

Step6.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
