import { Box, Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as ChevronRight } from "../../../assets/images/chevron-right.svg";
import { ReactComponent as PdfFile } from "../../../assets/images/file-pdf.svg";

import { useStyles } from "./Eligibility.style";

export const Altaroc2022Info = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.bluePanel}>
      <Grid item xs={12}>
        <Typography align="left" className={classes.bigTitleSecondary}>
          <FormattedMessage id="privateEquity.altaroc2022.title" />
        </Typography>
        <Typography align="left" className={classes.bigContentSecondary}>
          <FormattedMessage id="privateEquity.altaroc2022.content" />
        </Typography>
        <Box style={{ paddingTop: 5 }}>
          <Link
            href="https://www.altaroc.pe/millesimes/2023/"
            underline="always"
            className={classes.mediumLinkSecondary}
            target={"_blank"}
          >
            <FormattedMessage id="privateEquity.toKnowMore" />
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.subSection}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={6} sm={4}>
            <Typography align="left" className={classes.mediumContentSecondary}>
              <FormattedMessage id="privateEquity.altaroc2022.sizeHeader" />
            </Typography>
            <Typography align="left" className={classes.hugeContentSecondary}>
              <FormattedMessage id="privateEquity.altaroc2022.sizeContent" />
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography align="left" className={classes.mediumContentSecondary}>
              <FormattedMessage id="privateEquity.altaroc2022.durationHeader" />
            </Typography>
            <Typography align="left" className={classes.hugeContentSecondary}>
              <FormattedMessage id="privateEquity.altaroc2022.durationContent" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography align="left" className={classes.mediumContentSecondary}>
              <FormattedMessage id="privateEquity.altaroc2022.minimumSubscriptionHeader" />
            </Typography>
            <Typography align="left" className={classes.hugeContentSecondary}>
              <FormattedMessage id="privateEquity.altaroc2022.minimumSubscriptionContent" />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.subSection}>
        <Typography align="left" className={classes.mediumTitleSecondary}>
          <FormattedMessage id="privateEquity.altaroc2022.fundsInformationTitle" />
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent={"center"}
          alignContent={"center"}
        >
          <Grid item xs={12}>
            <Box className={classes.fundsInfoDivider} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.fundsInfoRowHeader}>
              <FormattedMessage id="privateEquity.altaroc2022.formatHeader" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.boldMediumContentSecondary}>
              <FormattedMessage id="privateEquity.altaroc2022.formatContent" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.fundsInfoDivider} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.fundsInfoRowHeader}>
              <FormattedMessage id="privateEquity.altaroc2022.fundsHeader" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.boldMediumContentSecondary}>
              <Link
                href="https://www.altaroc.pe/app/uploads/2022/04/5.-document-article-8-infos_precontractuelles_ag22-v-sitea.pdf"
                underline="always"
                className={classes.mediumLinkSecondary}
                target={"_blank"}
              >
                <FormattedMessage id="privateEquity.altaroc2022.article8ClassifiedFundsLink" />
              </Link>
              <FormattedMessage id="privateEquity.altaroc2022.fundsContent" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box spacing={1} className={classes.fundsInfoDivider} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.fundsInfoRowHeader}>
              <FormattedMessage id="privateEquity.altaroc2022.managementCompanyHeader" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.boldMediumContentSecondary}>
              <FormattedMessage id="privateEquity.altaroc2022.managementCompanyContent" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box spacing={1} className={classes.fundsInfoDivider} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.fundsInfoRowHeader}>
              <FormattedMessage id="privateEquity.altaroc2022.subscriberHeader" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.boldMediumContentSecondary}>
              <FormattedMessage id="privateEquity.altaroc2022.subscriberContent" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box spacing={1} className={classes.fundsInfoDivider} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.fundsInfoRowHeader}>
              <FormattedMessage id="privateEquity.altaroc2022.firstClosingHeader" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.boldMediumContentSecondary}>
              <FormattedMessage id="privateEquity.altaroc2022.firstClosingContent" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box spacing={1} className={classes.fundsInfoDivider} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.fundsInfoRowHeader}>
              <FormattedMessage id="privateEquity.altaroc2022.lastClosingHeader" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.boldMediumContentSecondary}>
              <FormattedMessage id="privateEquity.altaroc2022.lastClosingContent" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box spacing={1} className={classes.fundsInfoDivider} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.fundsInfoRowHeader}>
              <FormattedMessage id="privateEquity.altaroc2022.followingClosingsHeader" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.boldMediumContentSecondary}>
              <FormattedMessage id="privateEquity.altaroc2022.followingClosingsContent" />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.subSection}>
        <Typography className={classes.mediumTitleSecondary}>
          <FormattedMessage id="privateEquity.altaroc2022.importantDocumentsTitle" />
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent={"center"}
          alignContent={"center"}
        >
          <Grid item xs={12}>
            <Box className={classes.fundsInfoDivider} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Typography className={classes.fundsInfoRowHeader}>
                <PdfFile alt={""} />
                &nbsp;&nbsp;
                <FormattedMessage id="privateEquity.altaroc2022.keyInfoForSubscriberDocumentTitle" />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              <Typography className={classes.boldMediumContentSecondary}>
                <Link
                  href="https://static.yomoni.fr/assetmanagement/private-equity/altaroc/2023/Document_Information_Cl%C3%A9_2023.pdf"
                  underline="always"
                  className={classes.mediumLinkSecondary}
                  target={"_blank"}
                >
                  <FormattedMessage id="privateEquity.altaroc2022.seeTheDocument" />
                </Link>
              </Typography>
              <ChevronRight alt={""} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.fundsInfoDivider} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Typography className={classes.fundsInfoRowHeader}>
                <PdfFile alt={""} />
                &nbsp;&nbsp;
                <FormattedMessage id="privateEquity.altaroc2022.businessPitchDocumentTitle" />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              <Typography className={classes.boldMediumContentSecondary}>
                <Link
                  href="https://static.yomoni.fr/assetmanagement/private-equity/altaroc/2023/Pr%C3%A9sentation_commerciale_Altaroc_2023.pdf"
                  underline="always"
                  className={classes.mediumLinkSecondary}
                  target={"_blank"}
                >
                  <FormattedMessage id="privateEquity.altaroc2022.seeTheDocument" />
                </Link>
              </Typography>
              <ChevronRight alt={""} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.fundsInfoDivider} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Typography className={classes.fundsInfoRowHeader}>
                <PdfFile alt={""} />
                &nbsp;&nbsp;
                <FormattedMessage id="privateEquity.altaroc2022.regulationsTitle" />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              <Typography className={classes.boldMediumContentSecondary}>
                <Link
                  href="https://static.yomoni.fr/assetmanagement/private-equity/altaroc/2023/R%C3%A8glement_du_fond_Altaroc_2023.pdf"
                  underline="always"
                  className={classes.mediumLinkSecondary}
                  target={"_blank"}
                >
                  <FormattedMessage id="privateEquity.altaroc2022.seeTheDocument" />
                </Link>
              </Typography>
              <ChevronRight alt={""} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.subSection}>
        <Typography align="left" className={classes.mediumTitleSecondary}>
          <FormattedMessage id="privateEquity.altaroc2022.feesTitle" />
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent={"center"}
          alignContent={"center"}
        >
          <Grid item xs={12}>
            <Box className={classes.fundsInfoDivider} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.fundsInfoRowHeader}>
              <FormattedMessage id="privateEquity.altaroc2022.underlyingManagementFeesHeader" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography
              align="right"
              className={classes.boldMediumContentSecondary}
            >
              <FormattedMessage id="privateEquity.altaroc2022.underlyingManagementFeesContent" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box spacing={1} className={classes.fundsInfoDivider} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.fundsInfoRowHeader}>
              <FormattedMessage id="privateEquity.altaroc2022.managementFeesHeader" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography
              align="right"
              className={classes.boldMediumContentSecondary}
            >
              <FormattedMessage id="privateEquity.altaroc2022.managementFeesContent" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box spacing={1} className={classes.fundsInfoDivider} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.fundsInfoRowHeader}>
              <FormattedMessage id="privateEquity.altaroc2022.performanceFeesHeader" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.boldMediumContentSecondary}>
              <FormattedMessage id="privateEquity.altaroc2022.performanceFeesContent" />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
