import { Box, Grid, Typography, Avatar, Button } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";

import { ReactComponent as Advisors } from "../../../assets/images/advisors.svg";
import { ReactComponent as ArrowDownLeftCurve } from "../../../assets/images/arrow-down-left-curve.svg";
import { ReactComponent as ArrowDownRightCurve } from "../../../assets/images/arrow-down-right-curve.svg";
import { ReactComponent as Check } from "../../../assets/images/check.svg";
import { typography } from "../../../assets/styles/themes/typography";
import { StepBar } from "../../../components/stepper/StepBar";
import { StepperTitle } from "../../../components/stepper/StepperTitle";
import { steps } from "../steps/stepsDefinition";
import { Altaroc2022Info } from "./Ataroc2022Info";
import { HowToSubscribe } from "./HowToSubscribe";
import { PrivateEquityFunctioning } from "./PrivateEquityFunctioning";
import { Risks } from "./Risks";
import { WhatIsPrivateEquity } from "./WhatIsPrivateEquity";
import { YomoniAltaroc } from "./YomoniAltaroc";

import { useStyles } from "./Eligibility.style";

export const Eligible = () => {
  const intl = useIntl();
  const { url } = useRouteMatch();
  const classes = useStyles();

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: 1,
      }}
    >
      <Box sx={{ gridRow: "1" }}>
        <StepperTitle
          count={Object.keys(steps).length}
          current={Object.keys(steps).length}
          displayCurrentStep={false}
          label={intl.formatMessage({
            id: `privateEquity.result.title`,
          })}
          title={intl.formatMessage({ id: "privateEquity.title" })}
        />
        <StepBar
          count={Object.keys(steps).length}
          current={Object.keys(steps).length - 1}
        />
      </Box>
      <Box sx={{ gridRow: "2" }}>
        <Grid container className={classes.greyPanel}>
          <Grid item xs={12} sm={1}>
            <Avatar
              style={{ height: 36, width: 36 }}
              className={classes.greenBulletPoint}
            >
              <Check alt={""} />
            </Avatar>
          </Grid>
          <Grid item xs={12} sm={11}>
            <Typography align="left" className={classes.bigTitle}>
              <FormattedMessage id="privateEquity.eligible.eligibleTitle" />
            </Typography>
            <Grid container className={classes.subSection}>
              <Grid item xs={12}>
                <Typography align="left" className={classes.bigContent}>
                  <FormattedMessage id="privateEquity.eligible.eligibleContentStart" />
                  <b>
                    <FormattedMessage id="privateEquity.yomonixAltaroc" />
                  </b>
                  <FormattedMessage id="privateEquity.eligible.eligibleContentEnd" />
                </Typography>
              </Grid>
            </Grid>

            <WhatIsPrivateEquity />

            <YomoniAltaroc />

            <Risks />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ gridRow: "3" }}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.mainSection}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Advisors style={{ width: 64, borderRadius: "50%" }} alt={""} />
            </Box>
          </Grid>
          <Grid item xs={1} sm={2}>
            <Grid
              container
              justifyContent="center"
              alignItems="flex-end"
              className={classes.mainSection}
            >
              <Grid item className={classes.curvedArrow}>
                <ArrowDownLeftCurve />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10} sm={8}>
            <Typography
              align={"center"}
              className={classes.bigTitle}
              style={{
                minHeight: 140,
              }}
            >
              <FormattedMessage id="privateEquity.eligible.recommendedSubscriptionTitle" />
            </Typography>
          </Grid>
          <Grid item xs={1} sm={2}>
            <Grid
              container
              justifyContent="center"
              alignItems="flex-end"
              className={classes.mainSection}
            >
              <Grid item className={classes.curvedArrow}>
                <ArrowDownRightCurve />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ gridRow: "4" }} className={classes.subSection}>
        <Altaroc2022Info />
      </Box>

      <Box sx={{ gridRow: "5" }}>
        <PrivateEquityFunctioning />
      </Box>

      <Box sx={{ gridRow: "6" }}>
        <HowToSubscribe />
      </Box>
      <Box sx={{ gridRow: "7" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          className={classes.subSection}
        >
          <Grid item xs={6}>
            <Button
              color="primary"
              fullWidth={true}
              size="large"
              style={{
                minHeight: "60px",
                fontWeight: typography.fontWeightRegular,
              }}
              variant="contained"
              href={url + "/signature"}
            >
              <FormattedMessage
                style={{
                  textTransform: "uppercase",
                }}
                id="privateEquity.sign"
              />
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ gridRow: "8" }} className={classes.lastSection} />
    </Box>
  );
};
