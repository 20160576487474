import { Box, Button } from "@material-ui/core";
import { withTheme } from "@rjsf/core";
import { Theme as MaterialUiTheme } from "@rjsf/material-ui";
import cx from "classnames";
import { forwardRef } from "react";
import { FormattedMessage } from "react-intl";

import { ArrayFieldTemplate, TitleField } from "../../fields";
import { useTransformErrors } from "../../utils/form";
import {
  CheckboxesWidget,
  DateWidget,
  EmailWidget,
  FileWidget,
  NumberPatternWidget,
  NumberWidget,
  RadioWidget,
  RangeWidget,
  TextWidget,
} from "../../widgets";

import { useStyles } from "./Form.style.js";

const Theme = {
  ...MaterialUiTheme,
  ArrayFieldTemplate,
  fields: {
    ...MaterialUiTheme.fields,
    TitleField,
  },
  widgets: {
    ...MaterialUiTheme.widgets,
    CheckboxesWidget,
    DateWidget,
    EmailWidget,
    FileWidget,
    numberPatternWidget: NumberPatternWidget,
    RadioWidget,
    RangeWidget,
    TextWidget,
    UpDownWidget: NumberWidget,
  },
};

const FormBase = withTheme(Theme);

const DefaultChildren = (props) => {
  const { onPrevious, onPreviousLabel, onSubmitLabel } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.actions} marginTop={3}>
      <Button
        className={classes.button}
        color="default"
        size="large"
        onClick={onPrevious}
      >
        {!!onPreviousLabel ? (
          onPreviousLabel
        ) : (
          <FormattedMessage id="cta.precedent" />
        )}
      </Button>
      <Button className={classes.button} size="large" type="submit">
        {!!onSubmitLabel ? (
          onSubmitLabel
        ) : (
          <FormattedMessage id="cta.suivant" />
        )}
      </Button>
    </Box>
  );
};

export const Form = forwardRef((props, ref) => {
  const {
    children: childrenProp,
    className,
    onPrevious,
    onPreviousLabel,
    onSubmitLabel,
    ...other
  } = props;
  const classes = useStyles(props);
  const transformErrors = useTransformErrors();

  const children = childrenProp ? (
    childrenProp
  ) : (
    <DefaultChildren
      onPrevious={onPrevious}
      onPreviousLabel={onPreviousLabel}
      onSubmitLabel={onSubmitLabel}
    />
  );

  return (
    <FormBase
      {...other}
      className={cx(classes.root, className)}
      ref={ref}
      transformErrors={transformErrors}
    >
      {children}
    </FormBase>
  );
});
