import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Form } from "../../../components/form/Form";

const getSchema = (values, intl) => {
  return {
    type: "object",
    required: ["FPCIKnowledge5"],
    properties: {
      FPCIKnowledge5: {
        type: "string",
        title: intl.formatMessage({
          id: "privateEquity.step14.FPCIKnowledge5",
        }),
        oneOf: [
          {
            title: intl.formatMessage({
              id: "privateEquity.FPCIKnowledge5.yesFrom100k",
            }),
            const: "yesFrom100k",
          },
          {
            title: intl.formatMessage({
              id: "privateEquity.FPCIKnowledge5.yesNoMatterTheAmount",
            }),
            const: "yesNoMatterTheAmount",
          },
          {
            title: intl.formatMessage({ id: "non" }),
            const: "no",
          },
          {
            title: intl.formatMessage({ id: "iDontKnow" }),
            const: "iDontKnow",
          },
        ],
      },
    },
  };
};

const uiSchema = {
  "ui:order": ["FPCIKnowledge5"],
  FPCIKnowledge5: {
    "ui:widget": "radio",
  },
};

export const Step14 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
      />
    </Slide>
  );
};

Step14.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
