import { Box, Fab, Grid, IconButton } from "@material-ui/core";
import { Add, ArrowDownward, ArrowUpward, Remove } from "@material-ui/icons";
import { utils } from "@rjsf/core";

const { isMultiSelect, getDefaultRegistry } = utils;

export const ArrayFieldTemplate = (props) => {
  const { schema, registry = getDefaultRegistry() } = props;

  if (isMultiSelect(schema, registry.rootSchema)) {
    return <DefaultFixedArrayFieldTemplate {...props} />;
  } else {
    return <DefaultNormalArrayFieldTemplate {...props} />;
  }
};

const ArrayFieldTitle = (props) => {
  const { TitleField, idSchema, title, required } = props;
  if (!title) {
    return null;
  }

  const id = `${idSchema.$id}__title`;
  return <TitleField id={id} title={title} required={required} />;
};

const ArrayFieldDescription = ({ DescriptionField, idSchema, description }) => {
  if (!description) {
    return null;
  }

  const id = `${idSchema.$id}__description`;
  return <DescriptionField id={id} description={description} />;
};

// Used in the two templates
const DefaultArrayItem = (props) => {
  return (
    <Grid container={true} key={props.key} alignItems="center">
      <Grid item={true} xs>
        <Box p={2}>{props.children}</Box>
      </Grid>

      {props.hasToolbar && (
        <Grid item={true}>
          {(props.hasMoveUp || props.hasMoveDown) && (
            <IconButton
              tabIndex={-1}
              disabled={props.disabled || props.readonly || !props.hasMoveUp}
              onClick={props.onReorderClick(props.index, props.index - 1)}
            >
              <ArrowUpward />
            </IconButton>
          )}

          {(props.hasMoveUp || props.hasMoveDown) && (
            <IconButton
              tabIndex={-1}
              disabled={props.disabled || props.readonly || !props.hasMoveDown}
              onClick={props.onReorderClick(props.index, props.index + 1)}
            >
              <ArrowDownward />
            </IconButton>
          )}

          {props.hasRemove && (
            <IconButton
              tabIndex={-1}
              disabled={props.disabled || props.readonly}
              onClick={props.onDropIndexClick(props.index)}
            >
              <Remove />
            </IconButton>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const DefaultFixedArrayFieldTemplate = (props) => {
  return (
    <fieldset className={props.className}>
      <ArrayFieldTitle
        key={`array-field-title-${props.idSchema.$id}`}
        TitleField={props.TitleField}
        idSchema={props.idSchema}
        title={props.uiSchema["ui:title"] || props.title}
        required={props.required}
      />

      {(props.uiSchema["ui:description"] || props.schema.description) && (
        <div
          className="field-description"
          key={`field-description-${props.idSchema.$id}`}
        >
          {props.uiSchema["ui:description"] || props.schema.description}
        </div>
      )}

      <div
        className="row array-item-list"
        key={`array-item-list-${props.idSchema.$id}`}
      >
        {props.items && props.items.map(DefaultArrayItem)}
      </div>

      {props.canAdd && (
        <Fab
          disabled={props.disabled || props.readonly}
          onClick={props.onAddClick}
        >
          <Add />
        </Fab>
      )}
    </fieldset>
  );
};

const DefaultNormalArrayFieldTemplate = (props) => {
  return (
    <Box>
      <ArrayFieldTitle
        key={`array-field-title-${props.idSchema.$id}`}
        TitleField={props.TitleField}
        idSchema={props.idSchema}
        title={props.uiSchema["ui:title"] || props.title}
        required={props.required}
      />

      {(props.uiSchema["ui:description"] || props.schema.description) && (
        <ArrayFieldDescription
          key={`array-field-description-${props.idSchema.$id}`}
          DescriptionField={props.DescriptionField}
          idSchema={props.idSchema}
          description={
            props.uiSchema["ui:description"] || props.schema.description
          }
        />
      )}

      <Grid container={true} key={`array-item-list-${props.idSchema.$id}`}>
        {props.items && props.items.map((p) => DefaultArrayItem(p))}

        {props.canAdd && (
          <Grid container justify="flex-end">
            <Grid item={true}>
              <Box mt={2}>
                <Fab
                  color="primary"
                  disabled={props.disabled || props.readonly}
                  onClick={props.onAddClick}
                >
                  <Add />
                </Fab>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
