import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as Bolt } from "../../../assets/images/bolt.svg";
import { ReactComponent as Piggybank } from "../../../assets/images/piggybank.svg";
import { ReactComponent as Refresh } from "../../../assets/images/refresh.svg";

import { useStyles } from "./Eligibility.style";
export const Risks = () => {
  const classes = useStyles();

  return (
    <Box className={classes.bigSection}>
      <Typography align="left" className={classes.mainRiskTitle}>
        <FormattedMessage id="privateEquity.risks.risksInvolvedTitle" />
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6} className={classes.smallSection}>
          <Grid container>
            <Grid item xs={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  style={{ height: 36, width: 36 }}
                  className={classes.whiteBulletPoint}
                >
                  <Refresh alt={""} />
                </Avatar>
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Typography align="left" className={classes.riskTitle}>
                <FormattedMessage id="privateEquity.risks.economicCyclesRiskTitle" />
              </Typography>
              <Typography align="left" className={classes.mediumContent}>
                <FormattedMessage id="privateEquity.risks.economicCyclesRiskContent" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.smallSection}>
          <Grid container>
            <Grid item xs={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  style={{ height: 36, width: 36 }}
                  className={classes.whiteBulletPoint}
                >
                  <Bolt style={{ height: 24 }} alt={""} />
                </Avatar>
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Typography align="left" className={classes.riskTitle}>
                <FormattedMessage id="privateEquity.risks.performanceRiskTitle" />
              </Typography>
              <Typography align="left" className={classes.mediumContent}>
                <FormattedMessage id="privateEquity.risks.performanceRiskContent" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} className={classes.smallSection}>
          <Grid container>
            <Grid item xs={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  style={{ height: 36, width: 36 }}
                  className={classes.whiteBulletPoint}
                >
                  <Piggybank alt={""} />
                </Avatar>
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Typography align="left" className={classes.riskTitle}>
                <FormattedMessage id="privateEquity.risks.liquidityRiskTitle" />
              </Typography>
              <Typography align="left" className={classes.mediumContent}>
                <FormattedMessage id="privateEquity.risks.liquidityRiskContent" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
