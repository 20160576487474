import { Typography } from "@material-ui/core";
import cx from "classnames";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { useStyles } from "./StepperTitle.style";

export const StepperTitle = (props) => {
  const {
    className,
    count = 1,
    current = 1,
    displaySteps = true,
    displayCurrentStep = true,
    label,
    title,
  } = props;
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <Typography className={classes.title} variant="h1">
        {title}
      </Typography>
      {displaySteps && (
        <div className={classes.stepper}>
          {displayCurrentStep && (
            <FormattedMessage
              id="stepper.steps"
              values={{
                current,
                count,
              }}
            />
          )}
          {displayCurrentStep && label && (
            <FormattedMessage id="stepper.separator" />
          )}
          {label && (
            <FormattedMessage
              id="stepper.label"
              values={{
                label,
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

StepperTitle.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  current: PropTypes.number,
  displaySteps: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.string,
};
