import { Step1 } from "./Step1";
import { Step10 } from "./Step10";
import { Step11 } from "./Step11";
import { Step12 } from "./Step12";
import { Step13 } from "./Step13";
import { Step14 } from "./Step14";
import { Step15 } from "./Step15";
import { Step16 } from "./Step16";
import { Step17 } from "./Step17";
import { Step18 } from "./Step18";
import { Step19 } from "./Step19";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";
import { Step5 } from "./Step5";
import { Step6 } from "./Step6";
import { Step7 } from "./Step7";
import { Step8 } from "./Step8";
import { Step9 } from "./Step9";

export const StepNames = {
  STEP_1: "STEP_1",
  STEP_2: "STEP_2",
  STEP_3: "STEP_3",
  STEP_4: "STEP_4",
  STEP_5: "STEP_5",
  STEP_6: "STEP_6",
  STEP_7: "STEP_7",
  STEP_8: "STEP_8",
  STEP_9: "STEP_9",
  STEP_10: "STEP_10",
  STEP_11: "STEP_11",
  STEP_12: "STEP_12",
  STEP_13: "STEP_13",
  STEP_14: "STEP_14",
  STEP_15: "STEP_15",
  STEP_16: "STEP_16",
  STEP_17: "STEP_17",
  STEP_18: "STEP_18",
  STEP_19: "STEP_19",
};

export const steps = [
  {
    component: Step1,
    name: StepNames.STEP_1,
    path: "step-1",
  },
  {
    component: Step2,
    name: StepNames.STEP_2,
    path: "step-2",
  },
  {
    component: Step3,
    name: StepNames.STEP_3,
    path: "step-3",
  },
  {
    component: Step4,
    name: StepNames.STEP_4,
    path: "step-4",
  },
  {
    component: Step5,
    name: StepNames.STEP_5,
    path: "step-5",
  },
  {
    component: Step6,
    name: StepNames.STEP_6,
    path: "step-6",
  },
  {
    component: Step7,
    name: StepNames.STEP_7,
    path: "step-7",
  },
  {
    component: Step8,
    name: StepNames.STEP_8,
    path: "step-8",
  },
  {
    component: Step9,
    name: StepNames.STEP_9,
    path: "step-9",
  },
  {
    component: Step10,
    name: StepNames.STEP_10,
    path: "step-10",
  },
  {
    component: Step11,
    name: StepNames.STEP_11,
    path: "step-11",
  },
  {
    component: Step12,
    name: StepNames.STEP_12,
    path: "step-12",
  },
  {
    component: Step13,
    name: StepNames.STEP_13,
    path: "step-13",
  },
  {
    component: Step14,
    name: StepNames.STEP_14,
    path: "step-14",
  },
  {
    component: Step15,
    name: StepNames.STEP_15,
    path: "step-15",
  },
  {
    component: Step16,
    name: StepNames.STEP_16,
    path: "step-16",
  },
  {
    component: Step17,
    name: StepNames.STEP_17,
    path: "step-17",
  },
  {
    component: Step18,
    name: StepNames.STEP_18,
    path: "step-18",
  },
  {
    component: Step19,
    name: StepNames.STEP_19,
    path: "step-19",
  },
];
