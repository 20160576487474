import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: "inline-block",
      position: "relative",
    },
    top: {
      color: alpha(theme.palette.primary.light, 0.1),
    },
    bottom: {
      position: "absolute",
      left: 0,
    },
  }),
  { name: "CircularProgress" }
);
