import { Backdrop, Modal } from "@material-ui/core";
import PropTypes from "prop-types";

import { CircularProgress } from "../progress-bar/CircularProgress";

import { useStyles } from "./LoadingModal.style";

export const LoadingModal = (props) => {
  const { open } = props;
  const classes = useStyles(props);

  return (
    <Modal
      BackdropComponent={Backdrop}
      BackdropProps={{ classes: { root: classes.backdrop } }}
      className={classes.modal}
      open={open}
    >
      <CircularProgress
        ContainerProps={{ className: classes.container, tabIndex: "-1" }}
        variant="indeterminate"
      />
    </Modal>
  );
};

LoadingModal.propTypes = {
  open: PropTypes.bool,
};
