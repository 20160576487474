import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Form } from "../../components/form/Form";
import { createNumberOptions } from "../../utils/form";
import { capitalize } from "../../utils/string";

const getSchema = (values, intl) => {
  const schema = {
    type: "object",
    required: [],
    properties: {
      proportionImmobilierPatrimoine: {
        type: "number",
        title: intl.formatMessage({
          id: "360.etape4.proportionImmobilierPatrimoine",
        }),
        minimum: 0,
        maximum: 100,
        multipleOf: 5,
        default: 0,
      },
      creditsImmobilier: {
        type: "number",
        title: intl.formatMessage({ id: "360.etape4.creditsImmobilier" }),
      },
      compositionPatrimoineImmobilier: {
        type: "array",
        title: intl.formatMessage({
          id: "360.etape4.compositionPatrimoineImmobilier",
        }),
        uniqueItems: true,
        items: {
          type: "string",
          oneOf: [
            {
              title: intl.formatMessage({
                id: "typeBienPatrimoineImmobilier.residencePrincipale",
              }),
              const: "residencePrincipale",
            },
            {
              title: intl.formatMessage({
                id: "typeBienPatrimoineImmobilier.residenceSecondaire",
              }),
              const: "residenceSecondaire",
            },
            {
              title: intl.formatMessage({
                id: "typeBienPatrimoineImmobilier.indivisionFamiliale",
              }),
              const: "indivisionFamiliale",
            },
            {
              title: intl.formatMessage({
                id: "typeBienPatrimoineImmobilier.immobilierLocatif",
              }),
              const: "immobilierLocatif",
            },
            {
              title: intl.formatMessage({
                id: "typeBienPatrimoineImmobilier.immobilierDefiscalisation",
              }),
              const: "immobilierDefiscalisation",
            },
            {
              title: intl.formatMessage({
                id: "typeBienPatrimoineImmobilier.scpi",
              }),
              const: "scpi",
            },
          ],
        },
      },
    },
  };

  if (values) {
    if (values.proportionImmobilierPatrimoine > 0) {
      schema.required.push("compositionPatrimoineImmobilier");
      schema.properties.compositionPatrimoineImmobilier.minItems = 1;
    }
    if (values.compositionPatrimoineImmobilier) {
      values.compositionPatrimoineImmobilier.forEach((type) => {
        schema.properties[type] = {
          type: "number",
          title: intl.formatMessage({
            id: `typeBienPatrimoineImmobilier.montant${capitalize(type)}`,
          }),
        };
      });
    }
  }

  return schema;
};

const uiSchema = {
  "ui:order": [
    "proportionImmobilierPatrimoine",
    "creditsImmobilier",
    "compositionPatrimoineImmobilier",
    "residencePrincipale",
    "residenceSecondaire",
    "indivisionFamiliale",
    "immobilierLocatif",
    "immobilierDefiscalisation",
    "scpi",
  ],
  proportionImmobilierPatrimoine: {
    "ui:widget": "range",
    "ui:options": {
      marks: [
        {
          value: 0,
          label: "0",
        },
        {
          value: 100,
          label: "100",
        },
      ],
      valueLabelDisplay: "on",
      valueLabelFormat: (number) => `${number}%`,
    },
  },
  creditsImmobilier: createNumberOptions({ adornment: "€" }),
  compositionPatrimoineImmobilier: {
    "ui:widget": "checkboxes",
  },
  residencePrincipale: createNumberOptions({ adornment: "€" }),
  residenceSecondaire: createNumberOptions({ adornment: "€" }),
  indivisionFamiliale: createNumberOptions({ adornment: "€" }),
  immobilierLocatif: createNumberOptions({ adornment: "€" }),
  immobilierDefiscalisation: createNumberOptions({ adornment: "€" }),
  scpi: createNumberOptions({ adornment: "€" }),
};

export const Step4 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
      />
    </Slide>
  );
};

Step4.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
