import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: "840px",
      padding: theme.spacing(2),
      margin: "0 auto",
    },
  }),
  { name: "Form360" }
);
