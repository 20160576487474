import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Form } from "../../components/form/Form";
import { createNumberOptions } from "../../utils/form";

const getSchema = (values, intl) => {
  const schema = {
    type: "object",
    required: ["situationProfessionnelle"],
    properties: {
      situationProfessionnelle: {
        type: "string",
        title: intl.formatMessage({
          id: "360.etape3.situationProfessionnelle",
        }),
        oneOf: [
          {
            title: intl.formatMessage({
              id: "situationProfessionnelle.salarie",
            }),
            const: "salarie",
          },
          {
            title: intl.formatMessage({
              id: "situationProfessionnelle.professionLiberale",
            }),
            const: "professionLiberale",
          },
          {
            title: intl.formatMessage({
              id: "situationProfessionnelle.entrepreneurIndependant",
            }),
            const: "entrepreneurIndependant",
          },
          {
            title: intl.formatMessage({
              id: "situationProfessionnelle.inactifRetraite",
            }),
            const: "inactifRetraite",
          },
        ],
      },
      anneesAvantRetraite: {
        type: "number",
        maximum: 80,
        title: intl.formatMessage({ id: "360.etape3.anneesAvantRetraite" }),
      },
      revenusBrutsAnnuelsFoyer: {
        type: "number",
        minimum: 0,
        title: intl.formatMessage({
          id: "360.etape3.revenusBrutsAnnuelsFoyer",
        }),
      },
      impotsRevenu: {
        type: "number",
        minimum: 0,
        title: intl.formatMessage({ id: "360.etape3.impotsRevenu" }),
      },
      capaciteEpargne: {
        type: "number",
        minimum: 0,
        title: intl.formatMessage({ id: "360.etape3.capaciteEpargne" }),
      },
      capaciteEpargneUtilisee: {
        type: "boolean",
        title: intl.formatMessage({
          id: "360.etape3.capaciteEpargneUtilisee",
        }),
        oneOf: [
          {
            title: intl.formatMessage({ id: "oui" }),
            const: true,
          },
          {
            title: intl.formatMessage({ id: "non" }),
            const: false,
          },
        ],
      },
    },
  };

  return schema;
};

export const Step3 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  const uiSchema = useMemo(
    () => ({
      anneesAvantRetraite: createNumberOptions({
        adornment: intl.formatMessage({ id: "unite.ans" }),
        maxLength: 2,
      }),
      revenusBrutsAnnuelsFoyer: createNumberOptions({
        adornment: intl.formatMessage({ id: "unite.euroParAn" }),
      }),
      impotsRevenu: createNumberOptions({
        adornment: intl.formatMessage({ id: "unite.euroParAn" }),
      }),
      capaciteEpargne: createNumberOptions({
        adornment: intl.formatMessage({ id: "unite.euroParMois" }),
        maxLength: 7,
      }),
      capaciteEpargneUtilisee: {
        "ui:widget": "radio",
        "ui:options": { inline: true },
      },
    }),
    [intl]
  );

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
      />
    </Slide>
  );
};

Step3.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
