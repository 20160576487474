import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Form } from "../../../components/form/Form";

const getSchema = (values, intl) => {
  return {
    type: "object",
    required: ["FPCIKnowledge3"],
    properties: {
      FPCIKnowledge3: {
        type: "string",
        title: intl.formatMessage({
          id: "privateEquity.step12.FPCIKnowledge3",
        }),
        oneOf: [
          {
            title: intl.formatMessage({ id: "true" }),
            const: "true",
          },
          {
            title: intl.formatMessage({ id: "false" }),
            const: "false",
          },
          {
            title: intl.formatMessage({ id: "iDontKnow" }),
            const: "iDontKnow",
          },
        ],
      },
    },
  };
};

const uiSchema = {
  "ui:order": ["FPCIKnowledge3"],
  FPCIKnowledge3: {
    "ui:widget": "radio",
  },
};

export const Step12 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
      />
    </Slide>
  );
};

Step12.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
