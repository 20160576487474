import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Form } from "../../../components/form/Form";
import { createNumberOptions } from "../../../utils/form";

const getSchema = (values, intl) => {
  return {
    type: "object",
    required: ["investmentAmountPlanned"],
    properties: {
      investmentAmountPlanned: {
        type: "number",
        minimum: 0,
        title: intl.formatMessage({
          id: "privateEquity.step2.investmentAmountPlanned",
        }),
      },
    },
  };
};

export const Step2 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  const uiSchema = useMemo(
    () => ({
      investmentAmountPlanned: createNumberOptions({
        adornment: intl.formatMessage({ id: "unite.euro" }),
        maxLength: 11,
      }),
    }),
    [intl]
  );

  const validate = (formData, errors) => {
    let hasErrors = false;
    if (formData.investmentAmountPlanned < 100000) {
      hasErrors = true;
    } else if (formData.investmentAmountPlanned % 10000) {
      hasErrors = true;
    }
    if (hasErrors) {
      errors.investmentAmountPlanned.addError(
        intl.formatMessage({ id: "investmentAmountPlanned.error" })
      );
    }

    return errors;
  };

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
        validate={validate}
      />
    </Slide>
  );
};

Step2.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
