import API from "@aws-amplify/api";
import DayjsUtils from "@date-io/dayjs";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import dayjs from "dayjs";
import fr from "dayjs/locale/fr";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { theme } from "../assets/styles/themes";
import awsconfig from "../aws-exports";
import { RouteChangeListener } from "../components/router";
import frMessages from "../lang/fr.json";
import { store } from "../redux/configureStore";
import { Form360 } from "./Form360";
import { FormPrivateEquity } from "./privateEquity";

/* Configure API Gateway */
API.configure(awsconfig);

/* Messages externalizing */
const locale = "fr";
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.locale(fr);

class LocalizedUtils extends DayjsUtils {
  getDatePickerHeaderText(date) {
    return date.format("D MMM YYYY");
  }
}

const handleRouteChange = (location, action) => {
  // Scroll restauration
  window.scrollTo(0, 0);
};

export const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <IntlProvider locale={locale} messages={frMessages}>
          <MuiPickersUtilsProvider locale={fr} utils={LocalizedUtils}>
            <Router>
              <RouteChangeListener onChange={handleRouteChange} />
              <Switch>
                <Route exact path="/">
                  <Redirect to="/360" />
                </Route>
                <Route path="/360">
                  <Form360 />
                </Route>
                <Route path="/privateEquity">
                  <FormPrivateEquity />
                </Route>
                {/* Page Not Found */}
                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </Router>
          </MuiPickersUtilsProvider>
        </IntlProvider>
      </ThemeProvider>
    </Provider>
  );
};
