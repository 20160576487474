import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as YomoniAltarocInline } from "../../../assets/images/yomoni-x-altaroc-inline.svg";
import { ReactComponent as YomoniAltarocColumn } from "../../../assets/images/yomoni-x-altaroc.svg";

import { useStyles } from "./Eligibility.style";

export const YomoniAltaroc = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.bigSection}
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12} sm={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          className={classes.yomoniAltarocInline}
        >
          <YomoniAltarocInline
            alt={intl.formatMessage({
              id: "privateEquity.yomoniAltaroc.image",
            })}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          className={classes.yomoniAltarocColumn}
        >
          <YomoniAltarocColumn
            alt={intl.formatMessage({
              id: "privateEquity.yomoniAltaroc.image",
            })}
          />
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box spacing={2} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography align="left" className={classes.mediumTitle}>
          <FormattedMessage id="privateEquity.yomoniAltaroc.title1" />
          <br />
          <FormattedMessage id="privateEquity.yomoniAltaroc.title2" />
        </Typography>
        <Typography align="left" className={classes.mediumContent}>
          <FormattedMessage id="privateEquity.yomoniAltaroc.content" />
        </Typography>
      </Grid>
    </Grid>
  );
};
