import { createPayloadAction } from "../utils";

export const IDENTIFY_USER = "IDENTIFY_USER";
export const TRACK_ELIGIBILITY = "TRACK_ELIGIBILITY";
export const TRACK_SIGNATURE = "TRACK_SIGNATURE";

const identifyUser = (privateEquity) => {
  return createPayloadAction(IDENTIFY_USER, {
    privateEquity,
  });
};

const trackEligibility = (eligible) => {
  return createPayloadAction(TRACK_ELIGIBILITY, { eligible });
};

const trackSignature = (signature) => {
  return createPayloadAction(TRACK_SIGNATURE, { signature });
};

export { identifyUser, trackEligibility, trackSignature };
