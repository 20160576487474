import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Form } from "../../components/form/Form";

const getSchema = (values, intl) => {
  const schema = {
    type: "object",
    required: ["theme"],
    properties: {
      theme: {
        type: "array",
        title: intl.formatMessage({ id: "360.etape1.theme" }),
        minItems: 1,
        maxItems: 3,
        uniqueItems: true,
        items: {
          type: "string",
          anyOf: [
            {
              title: intl.formatMessage({
                id: "theme.placerCapitauxDisponibles",
              }),
              const: "placerCapitauxDisponibles",
            },
            {
              title: intl.formatMessage({ id: "theme.optimiserPerformance" }),
              const: "optimiserPerformance",
            },
            {
              title: intl.formatMessage({
                id: "theme.gererEvenementImportant",
              }),
              const: "gererEvenementImportant",
            },
            {
              title: intl.formatMessage({ id: "theme.diversifierPatrimoine" }),
              const: "diversifierPatrimoine",
            },
            {
              title: intl.formatMessage({ id: "theme.preparerRetraite" }),
              const: "preparerRetraite",
            },
            {
              title: intl.formatMessage({ id: "theme.optimiserFiscalite" }),
              const: "optimiserFiscalite",
            },
          ],
        },
      },
    },
  };

  if (values) {
    if (values.theme && values.theme.includes("gererEvenementImportant")) {
      schema.properties.evenementImportant = {
        type: "string",
        pattern: "^[^<>{}[\\]]*$",
        maxLength: 1000,
        title: intl.formatMessage({ id: "360.etape1.evenementImportant" }),
      };
      schema.required.push("evenementImportant");
    }
  }

  return schema;
};

const uiSchema = {
  theme: {
    "ui:widget": "checkboxes",
  },
  evenementImportant: { "ui:widget": "textarea" },
};

export const Step1 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
      />
    </Slide>
  );
};

Step1.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
