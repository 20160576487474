import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Form } from "../../../components/form/Form";

const getSchema = (values, intl) => {
  return {
    type: "object",
    required: ["riskTolerance3"],
    properties: {
      riskTolerance3: {
        type: "string",
        title: intl.formatMessage({
          id: "privateEquity.step17.riskTolerance3",
        }),
        oneOf: [
          {
            title: intl.formatMessage({
              id: "riskTolerance3.50kGain20kOrMoreLoss",
            }),
            const: "50kGain20kOrMoreLoss",
          },
          {
            title: intl.formatMessage({
              id: "riskTolerance3.30kGain20kMaxLoss",
            }),
            const: "30kGain20kMaxLoss",
          },
          {
            title: intl.formatMessage({ id: "riskTolerance3.10kGain4kLoss" }),
            const: "10kGain4kLoss",
          },
          {
            title: intl.formatMessage({ id: "riskTolerance3.5kGain0Loss" }),
            const: "5kGain0Loss",
          },
        ],
      },
    },
  };
};

const uiSchema = {
  "ui:order": ["riskTolerance3"],
  riskTolerance3: {
    "ui:widget": "radio",
  },
};

export const Step17 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
      />
    </Slide>
  );
};

Step17.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
