import { palette } from "../palette";
import { typography } from "../typography";

export const MuiFormLabel = {
  overrides: {
    root: {
      ...typography.h2,
      marginBottom: "0.6rem",
      "&$focused": {
        color: palette.text.primary,
      },
    },
  },
};
