import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "block",
      width: "100%",
      marginBottom: theme.spacing(1),
      padding: 0,
    },
    step: {
      display: "inline-block",
      height: "0.4rem",
      backgroundColor: theme.palette.primary.main,
      opacity: 0.1,
      "&:not(:first-child)": {
        marginLeft: theme.spacing(0.5),
      },
    },
    checked: {
      opacity: 1,
    },
  }),
  { name: "StepBar" }
);
