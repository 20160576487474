import { makeStyles, alpha } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
      cursor: "pointer",
      transition: "all 0.3s",
      textAlign: "center",
      minWidth: "12rem",
      padding: theme.spacing(0.5, 2),
      margin: theme.spacing(1, 1, 1, 0),
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      boxShadow: "none",
      borderRadius: theme.shape.borderRadius,
      "&:hover:not($disabled), &:focus:not($disabled)": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
      "&:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
    disabled: {
      color: theme.palette.text.disabled,
      cursor: "not-allowed",
    },
    checked: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      "&$disabled": {
        backgroundColor: alpha(theme.palette.primary.main, 0.7),
      },
    },
    input: {
      cursor: "inherit",
      position: "absolute",
      opacity: 0,
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      margin: 0,
      padding: 0,
      zIndex: 1,
    },
  }),
  { name: "RadioCard" }
);
