import { Slide } from "@material-ui/core";
import dayjs from "dayjs";
import fr from "dayjs/locale/fr";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Form } from "../../components/form/Form";

const getSchema = (values, intl) => {
  const schema = {
    type: "object",
    required: ["dateNaissance", "situationFamiliale", "enfants"],
    properties: {
      dateNaissance: {
        type: "string",
        format: "date",
        title: intl.formatMessage({ id: "360.etape2.dateNaissance" }),
      },
      situationFamiliale: {
        type: "string",
        title: intl.formatMessage({ id: "360.etape2.situationFamiliale" }),
        oneOf: [
          {
            title: intl.formatMessage({ id: "situationFamiliale.celibataire" }),
            const: "celibataire",
          },
          {
            title: intl.formatMessage({ id: "situationFamiliale.divorce" }),
            const: "divorce",
          },
          {
            title: intl.formatMessage({ id: "situationFamiliale.mariage" }),
            const: "mariage",
          },
          {
            title: intl.formatMessage({ id: "situationFamiliale.separation" }),
            const: "separation",
          },
          {
            title: intl.formatMessage({ id: "situationFamiliale.autre" }),
            const: "autre",
          },
          {
            title: intl.formatMessage({ id: "situationFamiliale.veuvage" }),
            const: "veuvage",
          },
          {
            title: intl.formatMessage({ id: "situationFamiliale.pacs" }),
            const: "pacs",
          },
        ],
      },
      enfants: {
        type: "string",
        title: intl.formatMessage({ id: "360.etape2.enfants" }),
        oneOf: [
          {
            title: intl.formatMessage({ id: "enfants.aucun" }),
            const: "aucun",
          },
          {
            title: intl.formatMessage({ id: "enfants.un" }),
            const: "un",
          },
          {
            title: intl.formatMessage({ id: "enfants.deux" }),
            const: "deux",
          },
          {
            title: intl.formatMessage({ id: "enfants.troisOuPlus" }),
            const: "troisOuPlus",
          },
        ],
      },
    },
  };

  if (values) {
    if (values.situationFamiliale === "mariage") {
      schema.properties.regimeMatrimonial = {
        type: "string",
        title: intl.formatMessage({ id: "360.etape2.regimeMatrimonial" }),
        oneOf: [
          {
            title: intl.formatMessage({
              id: "regimeMatrimonial.communauteLegaleReduiteAcquets",
            }),
            const: "communauteLegaleReduiteAcquets",
          },
          {
            title: intl.formatMessage({
              id: "regimeMatrimonial.communauteMeublesEtAcquets",
            }),
            const: "communauteMeublesEtAcquets",
          },
          {
            title: intl.formatMessage({
              id: "regimeMatrimonial.separationBiens",
            }),
            const: "separationBiens",
          },
          {
            title: intl.formatMessage({
              id: "regimeMatrimonial.participationAcquets",
            }),
            const: "participationAcquets",
          },
          {
            title: intl.formatMessage({
              id: "regimeMatrimonial.communauteUniverselle",
            }),
            const: "communauteUniverselle",
          },
          {
            title: intl.formatMessage({
              id: "regimeMatrimonial.communauteUniverselleClauseAttributionIntegrale",
            }),
            const: "communauteUniverselleClauseAttributionIntegrale",
          },
        ],
      };
      schema.required.push("regimeMatrimonial");
    }
    if (values.enfants && values.enfants !== "aucun") {
      schema.properties.enfantsACharge = {
        type: "boolean",
        title: intl.formatMessage({ id: "360.etape2.enfantsACharge" }),
        oneOf: [
          {
            title: intl.formatMessage({ id: "oui" }),
            const: true,
          },
          {
            title: intl.formatMessage({ id: "non" }),
            const: false,
          },
        ],
      };
      schema.required.push("enfantsACharge");
    }
  }

  return schema;
};

const uiSchema = {
  "ui:order": [
    "dateNaissance",
    "situationFamiliale",
    "regimeMatrimonial",
    "enfants",
    "enfantsACharge",
  ],
  dateNaissance: {
    "ui:options": {
      autoOk: true,
      format: fr.formats.L,
      openTo: "year",
      variant: "inline",
      views: ["year", "month", "date"],
    },
  },
  enfantsACharge: {
    "ui:widget": "radio",
    "ui:options": { inline: true },
  },
};

export const Step2 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  const validate = (formData, errors) => {
    if (formData.dateNaissance) {
      const birthdate = dayjs(formData.dateNaissance);
      if (birthdate.isAfter(dayjs().subtract(18, "year"))) {
        errors.dateNaissance.addError(
          intl.formatMessage({ id: "error.dateNaissance.max" })
        );
      }
    }
    return errors;
  };

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        validate={validate}
        uiSchema={uiSchema}
      />
    </Slide>
  );
};

Step2.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
