import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Form } from "../../../components/form/Form";

const EMAIL_REGEXP = new RegExp(
  "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$"
);

const getSchema = (values, intl, displayAdditionalFields) => {
  const schema = {
    type: "object",
    required: ["email"],
    properties: {
      email: {
        type: "string",
        title: intl.formatMessage({ id: "privateEquity.step19.email" }),
      },
    },
  };

  if (displayAdditionalFields) {
    schema.properties.firstname = {
      type: "string",
      maxLength: 30,
      title: intl.formatMessage({ id: "privateEquity.step19.firstname" }),
    };
    schema.properties.lastname = {
      type: "string",
      maxLength: 50,
      title: intl.formatMessage({ id: "privateEquity.step19.lastname" }),
    };
    schema.required.push("firstname");
    schema.required.push("lastname");
  }

  return schema;
};

const getParamLocalStorage = (param) => {
  return window.localStorage.getItem(param);
};

export const Step19 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  let displayAdditionalFields = false;
  const registeredEmail = getParamLocalStorage("email");
  if (registeredEmail && EMAIL_REGEXP.test(registeredEmail)) {
    formData.email = registeredEmail;
  } else {
    displayAdditionalFields = true;
  }

  const uiSchema = useMemo(
    () => ({
      email: {
        "ui:widget": "email",
        "ui:options": {
          helperText: intl.formatMessage({
            id: "privateEquity.step19.email.description",
          }),
        },
      },
      firstname: {
        "ui:widget": "text",
      },
      lastname: {
        "ui:widget": "text",
      },
    }),
    [intl]
  );

  const validate = (formData, errors) => {
    if (formData.email && !EMAIL_REGEXP.test(formData.email)) {
      errors.email.addError(intl.formatMessage({ id: "error.invalid" }));
    }
    return errors;
  };

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        onSubmitLabel={intl.formatMessage({ id: "cta.valider" })}
        schema={getSchema(formData, intl, displayAdditionalFields)}
        showErrorList={false}
        uiSchema={uiSchema}
        validate={validate}
      />
    </Slide>
  );
};

Step19.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
