import { FileUpload } from "../components/file-upload/FileUpload";
import { convertFileToDataUrl, extractFileFromDataUrl } from "./utils";

export const FileWidget = (props) => {
  const { onChange, value, ...other } = props;

  const file = extractFileFromDataUrl(value);

  const handleChange = (file) => {
    const dataUrl = convertFileToDataUrl(file, true);

    if (onChange) {
      onChange(dataUrl);
    }
  };

  const handleDelete = () => {
    if (onChange) {
      onChange(undefined);
    }
  };

  return (
    <FileUpload
      {...other}
      file={file}
      onChange={handleChange}
      onDelete={handleDelete}
    />
  );
};
