export const MuiCssBaseline = {
  overrides: {
    "@global": {
      "@font-face": { fontFamily: '"Fira Sans", sans-serif' },
      html: {
        height: "100%",
        MozOsxFontSmoothing: "auto", // Disable antialiasing
        WebkitFontSmoothing: "auto", // Disable antialiasing
      },
      body: {
        fontSize: "1.6rem", // Fix Material-UI body that uses body2 variant
        margin: 0,
        padding: 0,
      },
    },
  },
};
