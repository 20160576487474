import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";

const selectValue = (value, selected, all) => {
  const at = all.indexOf(value);
  const updated = selected.slice(0, at).concat(value, selected.slice(at));

  // As inserting values at predefined index positions doesn't work with empty
  // arrays, we need to reorder the updated selection to match the initial order
  return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
};

const deselectValue = (value, selected) => {
  return selected.filter((v) => v !== value);
};

export const CheckboxesWidget = (props) => {
  const {
    schema,
    label,
    id,
    disabled,
    options,
    value,
    autofocus,
    readonly,
    required,
    onChange,
    onBlur,
    onFocus,
  } = props;
  const {
    enumOptions,
    enumDisabled,
    inline,
    noneOptionValue = "none",
  } = options;

  const _onChange =
    (option) =>
    ({ target: { checked } }) => {
      const all = enumOptions.map(({ value }) => value);

      if (checked) {
        if (option.value === noneOptionValue) {
          onChange([noneOptionValue]);
        } else {
          let newSelection = deselectValue(noneOptionValue, value);
          onChange(selectValue(option.value, newSelection, all));
        }
      } else {
        onChange(deselectValue(option.value, value));
      }
    };

  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  const _onFocus = ({ target: { value } }) => onFocus(id, value);

  return (
    <>
      <FormLabel required={required} htmlFor={id}>
        {label || schema.title}
      </FormLabel>
      <FormGroup row={!!inline}>
        {enumOptions.map((option, index) => {
          const checked = value.indexOf(option.value) !== -1;
          const itemDisabled =
            enumDisabled && enumDisabled.indexOf(option.value) !== -1;
          const checkbox = (
            <Checkbox
              id={`${id}_${index}`}
              checked={checked}
              disabled={disabled || itemDisabled || readonly}
              autoFocus={autofocus && index === 0}
              onChange={_onChange(option)}
              onBlur={_onBlur}
              onFocus={_onFocus}
            />
          );
          return (
            <FormControlLabel
              control={checkbox}
              key={index}
              label={option.label}
            />
          );
        })}
      </FormGroup>
    </>
  );
};
