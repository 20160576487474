import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    visibleLabelSlider: {
      marginTop: theme.spacing(5),
    },
  }),
  { name: "RangeWidget" }
);
