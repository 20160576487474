import { Box, Link, Grid, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as Enterprises } from "../../../assets/images/enterprises.svg";

import { useStyles } from "./Eligibility.style";

export const WhatIsPrivateEquity = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Grid container className={classes.bigSection}>
      <Grid item xs={12} sm={8}>
        <Typography align="left" className={classes.mediumTitle}>
          <FormattedMessage id="privateEquity.summary.whatIsPrivateEquityTitle" />
        </Typography>
        <Typography align="left" className={classes.mediumContent}>
          <FormattedMessage id="privateEquity.summary.whatIsPrivateEquityContent" />
        </Typography>
        <Box style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Link
            href="https://www.altaroc.pe/private-equity-comprendre/"
            underline="always"
            className={classes.mediumLink}
            target={"_blank"}
          >
            <FormattedMessage id="privateEquity.toKnowMore" />
          </Link>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box spacing={2} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Enterprises
            alt={intl.formatMessage({
              id: "privateEquity.summary.enterprisesImage",
            })}
          />
        </Box>
        <Typography align={"center"} className={classes.enterprisesNote}>
          <FormattedMessage id="privateEquity.summary.enterprisesNote" />
        </Typography>
      </Grid>
    </Grid>
  );
};
