import NumberFormatBase from "react-number-format";

import { TextWidget } from "./TextWidget";

const NumberFormat = (props) => {
  const { id, inputRef, name, onChange, type, ...other } = props;

  return (
    <NumberFormatBase
      {...other}
      getInputRef={inputRef}
      name={name}
      onValueChange={(values) => {
        onChange({
          target: {
            id,
            name,
            type,
            value: values.value,
          },
        });
      }}
    />
  );
};

export const NumberWidget = (props) => {
  const { options = {}, ...otherProps } = props;

  const InputProps = options.InputProps || {};
  InputProps.inputComponent = NumberFormat;

  return <TextWidget {...otherProps} options={{ ...options, InputProps }} />;
};
