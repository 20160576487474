import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
    },
    actions: {
      textAlign: "right",
    },
    button: {
      margin: theme.spacing(0, 1),
    },
  }),
  { name: "Form" }
);
