import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Form } from "../../../components/form/Form";
import { createNumberOptions } from "../../../utils/form";

const getSchema = (values, intl) => {
  const schema = {
    type: "object",
    required: ["investmentHorizon"],
    properties: {
      investmentHorizon: {
        type: "number",
        minimum: 0,
        title: intl.formatMessage({
          id: "privateEquity.step4.investmentHorizon",
        }),
      },
    },
  };

  return schema;
};

export const Step4 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  const uiSchema = useMemo(
    () => ({
      investmentHorizon: createNumberOptions({
        adornment: intl.formatMessage({ id: "unite.ans" }),
        maxLength: 2,
      }),
    }),
    [intl]
  );

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
      />
    </Slide>
  );
};

Step4.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
