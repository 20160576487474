import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { generateId } from "../../utils/string";

const cachedScripts =
  (typeof window !== "undefined" && window._cachedScripts) || new Map();

/**
 * Dynamically load an external script and keep its state in cached.
 *
 * Options:
 * - key: the key to used in cache
 * - props: the props to pass to the script element
 *
 * @param {string} src - The URL of an external script file
 * @param {Object} [options] - The options
 */
export const useScript = (src, options = {}) => {
  // Keeping track of script loaded and error state
  const [errored, setErrored] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const key = options.key || generateId({ prefix: "script" });

    // If cachedScripts includes key that means another instance
    // of this hook has already loaded this script, so no need to load again.
    if (cachedScripts.has(key)) {
      cachedScripts.get(key).promise.then(() => {
        const { errored: cachedErrored, loaded: cachedLoaded } =
          cachedScripts.get(key);
        setErrored(cachedErrored);
        setLoaded(cachedLoaded);
      });
    } else {
      const tag = document.createElement("script");

      cachedScripts.set(key, {
        errored: false,
        loaded: false,
        promise: new Promise((resolve, reject) => {
          tag.async = true;
          tag.src = src;
          if (options.props) {
            Object.entries(options.props).forEach(
              ([propKey, propValue]) => (tag[propKey] = propValue)
            );
          }

          const handleResult = (state) => (event) => {
            const cached = cachedScripts.get(key);
            cached.errored = state === "error";
            cached.loaded = true;
            setErrored(cached.errored);
            setLoaded(cached.loaded);
            window._cachedScripts = cachedScripts;
            resolve();
          };

          tag.onload = handleResult("loaded");
          tag.onerror = handleResult("error");
          tag.onreadystatechange = () => {
            handleResult(tag.readyState);
          };
        }),
      });

      // Add script to document body
      document.body.appendChild(tag);
    }
  }, [options, src]);

  return [loaded, errored];
};

export const Script = (props) => {
  const { cachedKey, onError, onLoad, src, ...other } = props;
  const [loaded, error] = useScript(src, { key: cachedKey, props: other });

  useEffect(() => {
    if (error && onError) {
      onError();
    } else if (loaded && onLoad) {
      onLoad();
    }
  }, [error, loaded, onError, onLoad]);

  return null;
};

Script.propTypes = {
  cachedKey: PropTypes.string,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  src: PropTypes.string.isRequired,
};
