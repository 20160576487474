import { RESET_FORM, UPDATE_FORM } from "./actions";
import { createReducer } from "./utils";

const initialState = {};

/**
 * A case reducer that resets a form.
 *
 * @return {Object} The new state
 * @return {Object} The action
 */
const resetForm = (state, action) => {
  const { name } = action.payload;

  return Object.assign({}, state, {
    [name]: undefined,
  });
};

/**
 * A case reducer that updates a form.
 *
 * @return {Object} The new state
 * @return {Object} The action
 */
const updateForm = (state, action) => {
  const { name, data = {}, props = {} } = action.payload;
  const { data: currentFormData, props: currentFormProps } = state[name] || {};

  return Object.assign({}, state, {
    [name]: {
      data: { ...currentFormData, ...data },
      props: { ...currentFormProps, ...props },
    },
  });
};

/**
 * A slice reducer that handles forms.
 */
export const formsReducer = createReducer(initialState, {
  [RESET_FORM]: resetForm,
  [UPDATE_FORM]: updateForm,
});
