import { useTheme } from "@material-ui/core";
import cx from "classnames";
import PropTypes from "prop-types";

import { useStyles } from "./StepBar.style";

export const StepBar = (props) => {
  const { className, count, current } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <ul className={cx(classes.root, className)}>
      {Array.from(Array(count).keys()).map((step) => {
        const checked = step <= current;
        return (
          <li
            className={cx(classes.step, { [classes.checked]: checked })}
            key={step}
            style={{ width: `calc(${100 / count}% - ${theme.spacing(0.5)})` }}
          />
        );
      })}
    </ul>
  );
};

StepBar.displayName = "StepBar";

StepBar.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
};
