import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

import { ReactComponent as Check } from "../../../assets/images/check.svg";

import { useStyles } from "./Eligibility.style";

export const HowToSubscribe = () => {
  const classes = useStyles();
  const intl = useIntl();

  const sign = intl.formatMessage({ id: "privateEquity.sign" });

  const signatureNote = intl.formatMessage(
    { id: "privateEquity.howToSubscribe.signatureNote" },
    { action: "<b>" + sign + "</b>" }
  );

  return (
    <Box className={classes.mainSection}>
      <Typography align="left" className={classes.bigTitle}>
        <FormattedMessage id="privateEquity.howToSubscribe.title" />
      </Typography>
      <Grid container>
        <Grid item xs={12} className={classes.subSection}>
          <Grid container>
            <Grid item xs={12} sm={1} className={classes.howToSubscribeItem}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <Avatar
                  style={{ height: 36, width: 36 }}
                  className={classes.greenBulletPoint}
                >
                  <Check alt={""} />
                </Avatar>
              </Box>
            </Grid>
            <Grid item xs={12} sm>
              <Typography align="left" className={classes.howToSubscribeTitle}>
                <FormattedMessage id="privateEquity.howToSubscribe.eligibilityFormTitle" />
              </Typography>
              <Typography align="left" className={classes.mediumContent}>
                <FormattedMessage id="privateEquity.howToSubscribe.eligibilityFormContent" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.subSection}>
          <Grid container>
            <Grid item xs={12} sm={1} className={classes.howToSubscribeItem}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <Avatar
                  style={{ height: 36, width: 36 }}
                  className={classes.greyBulletPoint}
                >
                  <Typography>2</Typography>
                </Avatar>
              </Box>
            </Grid>
            <Grid item xs={12} sm>
              <Typography align="left" className={classes.howToSubscribeTitle}>
                <FormattedMessage id="privateEquity.howToSubscribe.signatureTitle" />
              </Typography>
              <Typography align="left" className={classes.mediumContent}>
                <b>
                  <FormattedMessage id="privateEquity.altaroc" />
                </b>
                <FormattedMessage id="privateEquity.howToSubscribe.signatureContent" />
              </Typography>
              <Typography
                align="left"
                className={classes.tinyNote}
                dangerouslySetInnerHTML={{ __html: signatureNote }}
              ></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
