import { KeyboardDatePicker } from "@material-ui/pickers";
import dayjs from "dayjs";

export const DateWidget = (props) => {
  const {
    autofocus: autoFocus,
    formContext,
    onChange,
    options = {},
    rawErrors,
    readonly: readOnly,
    uiSchema,
    value: valueProp,
    ...otherProps
  } = props;
  const {
    enumOptions,
    format: formatProp,
    minDate: minDateProp,
    maxDate: maxDateProp,
    ...otherOptions
  } = options;
  const value = dayjs(valueProp, "YYYY-MM-DD");
  const minDate = minDateProp && dayjs(minDateProp, "YYYY-MM-DD");
  const maxDate = maxDateProp && dayjs(maxDateProp, "YYYY-MM-DD");

  const handleChange = (dayjs, value) => {
    if (dayjs && dayjs.isValid()) {
      onChange(dayjs.format("YYYY-MM-DD"));
    }
  };

  return (
    <KeyboardDatePicker
      {...otherProps}
      {...otherOptions}
      autoFocus={autoFocus}
      error={!!(rawErrors && rawErrors.length)}
      format={formatProp}
      helperText={null}
      maxDate={maxDate}
      minDate={minDate}
      onChange={handleChange}
      readOnly={readOnly}
      value={value.isValid() ? value : null}
    />
  );
};
