import { Box } from "@material-ui/core";
import cx from "classnames";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useCallback, useEffect, useRef, useState } from "react";

import { Script } from "../../modules/script";
import { generateId } from "../../utils/string";

import { useStyles } from "./UniversignSignature.style";

// Universign new signature page
const TEST_NEW_URL = "sign.test.universign.eu/sig";
const PROD_NEW_URL = "app.universign.com/sig";

// Universign old signature page
const PROD_OLD_URL = "app.universign.com/fr/signature";

const isNewInterface = (url) =>
  url.indexOf(TEST_NEW_URL) !== -1 || url.indexOf(PROD_NEW_URL) !== -1;
const isProdEnvironment = (url) =>
  url.indexOf(PROD_NEW_URL) !== -1 || url.indexOf(PROD_OLD_URL) !== -1;

export const UniversignSignatureBase = (props) => {
  const {
    className,
    onCancel,
    onError,
    onSuccess,
    title = generateId({ prefix: "iframe" }),
    url,
  } = props;
  const containerId = generateId();
  const containerRef = useRef(null);
  const iframeRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const classes = useStyles();

  const handleUrlChange = useCallback(() => {
    try {
      let iframeUrl = iframeRef.current.contentWindow.location.href;
      if (iframeUrl.endsWith("success")) {
        setOpacity(0);
        if (onSuccess) {
          onSuccess();
        }
      } else if (iframeUrl.endsWith("fail")) {
        setOpacity(0);
        if (onError) {
          onError();
        }
      } else if (iframeUrl.endsWith("cancel")) {
        setOpacity(0);
        if (onCancel) {
          onCancel();
        }
      }
    } catch (error) {
      // prevent to display errors in console
      // when the iframe's source is not in the same domain than the parent's url
    }
  }, [onCancel, onError, onSuccess]);

  useEffect(() => {
    if (!url) {
      return;
    }

    if (isNewInterface(url)) {
      if (loaded) {
        const configuration = { redirectionMode: "IN" };
        const params = url.slice(url.indexOf("?"));
        const signerId = queryString.parse(params).id;
        const isProdEnvironnement = isProdEnvironment(url);
        // eslint-disable-next-line no-undef
        universignSigInit(
          containerId,
          signerId,
          configuration,
          !isProdEnvironnement && "https://sign.test.universign.eu"
        );
        iframeRef.current = containerRef.current.querySelector("iframe");
        iframeRef.current.classList.add(classes.iframe);
        iframeRef.current.addEventListener("load", handleUrlChange);
        return () => {
          iframeRef.current.removeEventListener("load", handleUrlChange);
        };
      }
    }
  }, [classes, containerId, handleUrlChange, loaded, url]);

  const onScriptError = () => {};

  const onScriptLoad = () => {
    setLoaded(true);
  };

  return (
    <div
      className={cx(classes.root, className)}
      id={containerId}
      ref={containerRef}
      style={{ opacity }}
      data-cs-mask
    >
      {url ? (
        isNewInterface(url) ? (
          <Script
            cachedKey="universign-snippet"
            onError={onScriptError}
            onLoad={onScriptLoad}
            src={`https://${
              isProdEnvironment(url) ? PROD_NEW_URL : TEST_NEW_URL
            }/embed.js`}
          />
        ) : (
          <iframe
            className={classes.iframe}
            onLoad={handleUrlChange}
            ref={iframeRef}
            src={url}
            title={title}
          />
        )
      ) : (
        <Box className={classes.loadingWrapper}>
          Pas de signature possible pour le moment
        </Box>
      )}
    </div>
  );
};

UniversignSignatureBase.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.string,
};

const UniversignSignature = UniversignSignatureBase;

export { UniversignSignature };
