import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Form } from "../../../components/form/Form";
import { createNumberPatternOptions } from "../../../utils/form";

const getSchema = (values, intl) => {
  return {
    type: "object",
    required: ["nif"],
    properties: {
      nif: {
        type: "string",
        title: intl.formatMessage({
          id: "privateEquity.step6.nif",
        }),
      },
    },
  };
};

export const Step6 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  const uiSchema = {
    "ui:order": ["nif"],
    nif: createNumberPatternOptions({ format: "## ## ### ### ###" }),
  };

  const validate = (formData, errors) => {
    if (formData.nif.replace(/\s/g, "").length !== 13) {
      errors.nif.addError(intl.formatMessage({ id: "error.nif.invalid" }));
    }
    return errors;
  };

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
        validate={validate}
      />
    </Slide>
  );
};

Step6.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
