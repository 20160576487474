export const MuiInputLabel = {
  overrides: {
    shrink: {
      position: "relative",
      transform: "none",
      "& + .MuiInput-formControl": {
        marginTop: 0,
      },
    },
  },
};
