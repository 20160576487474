import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Form } from "../../../components/form/Form";

const getSchema = (values, intl) => {
  return {
    type: "object",
    required: ["financialInvestmentsKnowledge"],
    properties: {
      financialInvestmentsKnowledge: {
        type: "string",
        title: intl.formatMessage({
          id: "privateEquity.step7.financialInvestmentsKnowledge",
        }),
        oneOf: [
          {
            title: intl.formatMessage({
              id: "financialInvestmentsKnowledge.none",
            }),
            const: "none",
          },
          {
            title: intl.formatMessage({
              id: "financialInvestmentsKnowledge.directManagement",
            }),
            const: "directManagement",
          },
          {
            title: intl.formatMessage({
              id: "financialInvestmentsKnowledge.advisoryManagement",
            }),
            const: "advisoryManagement",
          },
          {
            title: intl.formatMessage({
              id: "financialInvestmentsKnowledge.discretionaryManagement",
            }),
            const: "discretionaryManagement",
          },
        ],
      },
    },
  };
};

const uiSchema = {
  "ui:order": ["financialInvestmentsKnowledge"],
};

export const Step7 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
      />
    </Slide>
  );
};

Step7.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
