import {
  Box,
  Dialog,
  FormHelperText,
  IconButton,
  Paper,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import {
  CheckCircleRounded,
  Delete,
  DescriptionRounded,
  VerticalAlignBottomRounded,
  Visibility,
  InfoOutlined,
} from "@material-ui/icons";
import cx from "classnames";
import filesize from "filesize";
import PropTypes from "prop-types";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { CircularProgress } from "../progress-bar/CircularProgress";

import { useStyles } from "./FileUpload.style";

const isImage = (type) => type && type.indexOf("image/") === 0;

export const FileUpload = (props) => {
  const {
    options,
    error = false,
    file: fileProp,
    fullWidth = false,
    helperText,
    id,
    label,
    onChange,
    onDelete,
    onError,
    onUploadAsync,
  } = props;
  const [file, setFile] = useState(fileProp);
  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const classes = useStyles();

  const handleDelete = () => {
    if (onDelete) {
      onDelete(file.id);
    }
    setFile(null);
  };

  const handleError = (error) => {
    setLoading(false);
    if (onError) {
      onError(error);
    }
  };

  const handleUpload = async (event) => {
    const inputFile = event.target.files[0];
    // Upload canceled
    if (!inputFile) {
      return;
    }

    setLoading(true);
    const reader = new FileReader();
    reader.onerror = () => {
      handleError(reader.error);
    };
    reader.onload = async () => {
      try {
        const content = reader.result.toString().replace(/^.*base64,/, "");
        const url = window.URL.createObjectURL(inputFile);
        const file = {
          content,
          name: inputFile.name,
          size: inputFile.size,
          type: inputFile.type,
          url,
        };
        if (onUploadAsync) {
          await onUploadAsync(file);
        } else if (onChange) {
          onChange(file);
        }
        setFile(file);
      } catch (e) {
        handleError(e);
      }
      setLoading(false);
    };
    reader.readAsDataURL(inputFile);
  };

  const handlePreview = () => {
    setOpenPreview(true);
  };

  const handleClose = () => {
    setOpenPreview(false);
  };

  const renderUploadButton = () => {
    return (
      <>
        <Paper
          className={cx(classes.paper, classes.paperUpload, {
            [classes.paperError]: error,
          })}
          component="label"
          elevation={4}
          htmlFor={id}
        >
          <VerticalAlignBottomRounded className={classes.uploadIcon} />
          <FormattedMessage id="fileUpload.text">
            {(text) => <p className={classes.uploadText}>{text}</p>}
          </FormattedMessage>
          <FormattedMessage id="fileUpload.acceptedFormats">
            {(text) => <p className={classes.acceptedFormats}>{text}</p>}
          </FormattedMessage>
          {loading && (
            <CircularProgress ContainerProps={{ className: classes.loader }} />
          )}
          <input
            accept={options.accept}
            className={classes.input}
            disabled={loading}
            id={id}
            name="document"
            onChange={handleUpload}
            type="file"
          />
        </Paper>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </>
    );
  };

  const renderUploaded = () => (
    <Paper className={cx(classes.paper, classes.paperUploaded)} elevation={4}>
      <SvgIcon className={classes.fileIcon} component={DescriptionRounded} />
      <div className={classes.fileInfo}>
        <div className={classes.fileName}>
          {file.name}
          <CheckCircleRounded className={classes.checkIcon} />
        </div>
        {file.size && (
          <div className={classes.fileSize}>{filesize(file.size)}</div>
        )}
      </div>
      <div className={classes.actionIcons}>
        <IconButton onClick={handleDelete} size="small">
          <Delete fontSize="small" />
        </IconButton>
        {file.url && (
          <IconButton onClick={handlePreview} size="small">
            <Visibility fontSize="small" />
          </IconButton>
        )}
      </div>
    </Paper>
  );

  return (
    <Box className={cx(classes.root, { [classes.fullWidth]: fullWidth })}>
      {label && (
        <Typography variant="h2" component="span">
          {label}
        </Typography>
      )}
      {options.infoDetails && (
        <Box className={classes.infoDetailsBox}>
          <InfoOutlined className={classes.infoDetailsIcon} />
          <Typography className={classes.infoDetailsText}>
            {options.infoDetails}
          </Typography>
        </Box>
      )}
      {file ? renderUploaded() : renderUploadButton()}

      {openPreview && (
        <Dialog
          classes={{
            paper: cx(classes.dialogPaper, {
              [classes.dialogPdfPaper]: !isImage(file.type),
            }),
          }}
          onClose={handleClose}
          open={openPreview}
        >
          {isImage(file.type) ? (
            <img className={classes.image} alt={file.name} src={file.url} />
          ) : (
            <object
              data={file.url}
              height="100%"
              type="application/pdf"
              width="100%"
            >
              {file.name}
            </object>
          )}
        </Dialog>
      )}
    </Box>
  );
};

FileUpload.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.string,
    url: PropTypes.string,
  }),
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onDelete: PropTypes.func,
  onUploadAsync: PropTypes.func,
};
