import { Widgets } from "@rjsf/material-ui";

export const TextWidget = (props) => {
  const { options = {}, ...otherProps } = props;
  // Omit `emptyValue` and `enumOptions` from options and pass other to widget
  const { emptyValue, enumOptions, ...otherOptions } = options;

  return (
    <Widgets.TextWidget {...otherProps} {...otherOptions} options={options} />
  );
};
