import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backdrop: {
      background: alpha(theme.palette.background.default, 0.45),
    },
    container: { outline: "none" },
  }),
  { name: "LoadingModal" }
);
