import { typography } from "../typography";

export const MuiButton = {
  overrides: {
    root: {
      fontWeight: typography.fontWeightRegular,
      letterSpacing: ".1rem",
      "&:focus": {
        outline: 0,
      },
    },
    contained: {
      fontWeight: typography.fontWeightLight,
    },
    sizeLarge: {
      fontSize: "1.5rem",
    },
  },
  props: {
    color: "primary",
    size: "small",
    variant: "contained",
  },
};
