import "core-js/stable";
import "regenerator-runtime/runtime";
import ReactDOM from "react-dom";

import { App } from "./pages/App";
import * as serviceWorker from "./serviceWorker";

import "./index.css";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
