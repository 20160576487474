import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { isUrlAllowedOrValid } from "../../utils/functions";

import { useStyles } from "./StepConfirmation.style";

export const StepConfirmation = () => {
  const classes = useStyles();
  const intl = useIntl();

  const titreRetourSite = intl.formatMessage({
    id: "360.confirmation.cta.retourSiteYomoni",
  });
  const titreChoisirCreneau = intl.formatMessage({
    id: "360.confirmation.cta.choisirCreneau",
  });
  const [redirectUrl] = useState(() =>
    window.localStorage.getItem("redirectUrl")
  );
  return (
    <Grid alignItems="center" container direction="column" justify="center">
      <Typography>
        <FormattedMessage id="360.confirmation.text" />
      </Typography>
      {redirectUrl && isUrlAllowedOrValid(redirectUrl) && (
        <>
          <Typography className={classes.choisirCreneau}>
            <FormattedMessage id="360.confirmation.choisirCreneau" />
          </Typography>
          <Box mt={2} className={classes.boxPadding}>
            <Button href={redirectUrl} title={titreChoisirCreneau}>
              {titreChoisirCreneau}
            </Button>
          </Box>
          <Typography>
            <FormattedMessage id="ou" />
          </Typography>
        </>
      )}

      <Box mt={2}>
        <Button href="https://www.yomoni.fr" title={titreRetourSite}>
          {titreRetourSite}
        </Button>
      </Box>
    </Grid>
  );
};
