import PropTypes from "prop-types";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const RouteChangeListener = (props) => {
  const { onChange } = props;
  const history = useHistory();

  useEffect(() => {
    if (onChange) {
      // Listen for changes to the current location.
      const unlisten = history.listen((location, action) => {
        onChange(location, action);
      });
      return () => {
        unlisten();
      };
    }
  }, [history, onChange]);

  return null;
};

RouteChangeListener.propTypes = {
  history: PropTypes.object,
  onChange: PropTypes.func,
};
