import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as PrivateEquityFunctioningChart } from "../../../assets/images/private-equity-functioning-chart.svg";

import { useStyles } from "./Eligibility.style";

export const PrivateEquityFunctioning = () => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className={classes.mainSection}
      >
        <Grid item xs={12}>
          <Typography align={"center"} className={classes.bigTitle}>
            <FormattedMessage id="privateEquity.functioningGuide.title" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            className={classes.functioningChart}
          >
            <PrivateEquityFunctioningChart style={{ width: 741 }} alt={""} />
          </Box>
        </Grid>
      </Grid>

      <Box className={classes.bigSection}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className={classes.smallSection}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              <Avatar
                style={{ height: 36, width: 36 }}
                className={classes.blueBulletPoint}
              >
                1
              </Avatar>
            </Box>
            <Typography align="left" className={classes.functioningTitle}>
              <FormattedMessage id="privateEquity.functioningGuide.step1Title" />
            </Typography>
            <Typography align="left" className={classes.functioningSubTitle}>
              <FormattedMessage id="privateEquity.functioningGuide.step1SubTitle" />
            </Typography>
            <Typography align="left" className={classes.mediumContent}>
              <FormattedMessage id="privateEquity.functioningGuide.step1Content" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.smallSection}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              <Avatar
                style={{ height: 36, width: 36 }}
                className={classes.blueBulletPoint}
              >
                2
              </Avatar>
            </Box>
            <Typography align="left" className={classes.functioningTitle}>
              <FormattedMessage id="privateEquity.functioningGuide.step2Title" />
            </Typography>
            <Typography align="left" className={classes.functioningSubTitle}>
              <FormattedMessage id="privateEquity.functioningGuide.step2SubTitle" />
            </Typography>
            <Typography align="left" className={classes.mediumContent}>
              <FormattedMessage id="privateEquity.functioningGuide.step2Content" />
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className={classes.smallSection}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              <Avatar
                style={{ height: 36, width: 36 }}
                className={classes.blueBulletPoint}
              >
                3
              </Avatar>
            </Box>
            <Typography align="left" className={classes.functioningTitle}>
              <FormattedMessage id="privateEquity.functioningGuide.step3Title" />
            </Typography>
            <Typography align="left" className={classes.functioningSubTitle}>
              <FormattedMessage id="privateEquity.functioningGuide.step3SubTitle" />
            </Typography>
            <Typography align="left" className={classes.mediumContent}>
              <FormattedMessage id="privateEquity.functioningGuide.step3Content" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.smallSection}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              <Avatar
                style={{ height: 36, width: 36 }}
                className={classes.blueBulletPoint}
              >
                4
              </Avatar>
            </Box>
            <Typography align="left" className={classes.functioningTitle}>
              <FormattedMessage id="privateEquity.functioningGuide.step4Title" />
            </Typography>
            <Typography align="left" className={classes.functioningSubTitle}>
              <FormattedMessage id="privateEquity.functioningGuide.step4SubTitle" />
            </Typography>
            <Typography align="left" className={classes.mediumContent}>
              <FormattedMessage id="privateEquity.functioningGuide.step4Content" />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
