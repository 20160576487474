import API from "@aws-amplify/api";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  matchPath,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { LoadingModal } from "../../components/loading-modal/LoadingModal";
import { StepBar } from "../../components/stepper/StepBar";
import { StepperTitle } from "../../components/stepper/StepperTitle";
import { resetForm, updateForm } from "../../redux/actions";
import { identifyUser, trackEligibility } from "../../redux/cdp/actions";
import { getForm } from "../../redux/selectors";
import { addErrorMessages } from "../../utils/form";
import { camelize } from "../../utils/string";
import { Eligible } from "./eligibility/Eligible";
import { NotEligible } from "./eligibility/NotEligible";
import { Signature } from "./eligibility/Signature";
import { SignatureConfirmation } from "./eligibility/SignatureConfirmation";
import { StepIntro } from "./steps/StepIntro";
import { steps } from "./steps/stepsDefinition";

import { useStyles } from "./FormPrivateEquity.style.js";

addErrorMessages("format", "error.invalid");
addErrorMessages("maxItems", "error.array.max");
addErrorMessages("maxLength", "error.string.max");
addErrorMessages("maximum", "error.number.max");
addErrorMessages("minItems", "error.required");
addErrorMessages("minLength", "error.string.min");
addErrorMessages("minimum", "error.number.min");
addErrorMessages("pattern", "error.string.invalidCharacters");
addErrorMessages("required", "error.required");

const FORM_NAME = "PrivateEquity";

const ApiRequestState = {
  NONE: "NONE",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

const urlParams = ["email"];
const useSaveUrlParamsInStorage = (location) => {
  useEffect(() => {
    const getParamAndSaveLocalStorage = (param) => {
      const paramValue = new URLSearchParams(location.search).get(param);
      if (paramValue) {
        window.localStorage.setItem(param, paramValue);
      }
    };
    urlParams.forEach((param) => getParamAndSaveLocalStorage(param));
  }, [location]);
};
const clearSavedUrlParamsInStorage = () => {
  urlParams.forEach((param) => window.localStorage.removeItem(param));
};

export const FormPrivateEquity = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const [apiRequestState, setApiRequestState] = useState(ApiRequestState.NONE);
  const { path, url } = useRouteMatch();
  const matchingStepUrl = matchPath(location.pathname, {
    path: `${url}/:step?`,
    exact: true,
    strict: false,
  });
  const matchingEligible = matchPath(location.pathname, {
    path: `${url}/eligible/*`,
  });
  let currentStepPath = "";
  if (matchingStepUrl) {
    currentStepPath = matchingStepUrl.params.step || "";
  }

  const activeStepIndex = steps.findIndex(
    ({ path: stepPath }) => stepPath === currentStepPath
  );
  const { data: formData, props: formProps } = useSelector(getForm(FORM_NAME));
  const classes = useStyles();
  useSaveUrlParamsInStorage(location);

  // Redirect to base URL if no persisted form data or step URL too far
  useEffect(() => {
    if (
      (currentStepPath && !currentStepPath.includes("step")) ||
      matchingEligible
    ) {
      return;
    }

    if (
      !formData ||
      (formProps &&
        (formProps.step === undefined || activeStepIndex > formProps.step))
    ) {
      history.replace(url);
    }
  }, [
    activeStepIndex,
    formData,
    formProps,
    history,
    apiRequestState,
    currentStepPath,
    matchingEligible,
    url,
  ]);

  const handleChange = (values) => {
    // Ignore changes if not in step
    if (activeStepIndex === -1) {
      return;
    }
    dispatch(updateForm(FORM_NAME, values.formData));
  };

  const handlePrevious = () => {
    dispatch(updateForm(FORM_NAME, null, { step: activeStepIndex - 1 }));

    if (activeStepIndex === 0) {
      history.push(url);
    } else {
      const stepPath = steps[activeStepIndex - 1].path;
      history.push(`${url}${stepPath ? "/" + stepPath : ""}`);
    }
  };

  const handleSuccess = () => {
    dispatch(resetForm(FORM_NAME));
    clearSavedUrlParamsInStorage();
  };

  const handleSubmit = (values) => {
    if (
      apiRequestState === ApiRequestState.PENDING ||
      apiRequestState === ApiRequestState.SUCCESS
    ) {
      return;
    }

    // Intermediate step
    if (activeStepIndex + 1 < steps.length) {
      dispatch(
        updateForm(FORM_NAME, values.formData, { step: activeStepIndex + 1 })
      );
      // Redirect to the next step
      const stepPath = steps[activeStepIndex + 1].path;
      history.push(`${url}${stepPath ? "/" + stepPath : ""}`);
    }

    // Final step
    if (activeStepIndex + 1 === steps.length) {
      dispatch(updateForm(FORM_NAME, values.formData));

      let dataToSend = { ...values.formData };
      dataToSend.nif = dataToSend.nif.replace(/\s/g, "");
      setApiRequestState(ApiRequestState.PENDING);
      API.post("PrivateEquity", "/private-equity", {
        body: dataToSend,
      })
        .then((result) => {
          dispatch(
            identifyUser({
              email: formData.email,
              ...(formData.firstname && { firstName: formData.firstname }),
              ...(formData.lastname && { lastName: formData.lastname }),
              salesforceId: result.contactId,
            })
          );
          if (result.eligibility === "yes") {
            dispatch(
              trackEligibility({
                email: formData.email,
                eligibility: true,
              })
            );
            API.patch("PrivateEquity", `/private-equity/${result.id}/adviceDoc`)
              .then(() => {
                setApiRequestState(ApiRequestState.SUCCESS);
                history.push(`${url}/eligible/${result.id}`);
                handleSuccess();
              })
              .catch(() => {
                setApiRequestState(ApiRequestState.ERROR);
              });
          } else {
            dispatch(
              trackEligibility({
                email: formData.email,
                eligibility: false,
              })
            );
            setApiRequestState(ApiRequestState.SUCCESS);
            history.push(`${url}/notEligible`);
            handleSuccess();
          }
        })
        .catch(() => {
          setApiRequestState(ApiRequestState.ERROR);
        });
    }
  };

  const handleCloseDialog = () => {
    setApiRequestState(ApiRequestState.NONE);
  };

  return (
    <Grid
      alignItems="center"
      className={classes.root}
      container
      justifyContent={"center"}
    >
      <LoadingModal open={apiRequestState === ApiRequestState.PENDING} />
      {activeStepIndex >= 0 && (
        <Grid item xs={12}>
          <StepperTitle
            count={Object.keys(steps).length}
            current={activeStepIndex + 1}
            label={intl.formatMessage({
              id: `privateEquity.${camelize(
                steps[activeStepIndex].name.toLowerCase()
              )}.title`,
            })}
            title={intl.formatMessage({ id: "privateEquity.title" })}
          />
          <StepBar
            count={Object.keys(steps).length}
            current={activeStepIndex}
          />
        </Grid>
      )}
      <Switch>
        <Route exact path={`${path}`}>
          <StepIntro onSubmit={handleSubmit} />
        </Route>
        {formData &&
          steps.map(({ component: Component, name, path: stepPath }) => (
            <Route
              exact
              key={name}
              path={`${path}${stepPath ? "/" + stepPath : ""}`}
            >
              <Component
                formData={formData}
                onChange={handleChange}
                onPrevious={handlePrevious}
                onSubmit={handleSubmit}
              />
            </Route>
          ))}
        <Route exact path={`${path}/notEligible`}>
          <NotEligible />
        </Route>
        <Route exact path={`${path}/eligible/:privateEquityIdOrSignatureUrl`}>
          <Eligible />
        </Route>
        <Route
          exact
          path={`${path}/eligible/:privateEquityIdOrSignatureUrl/signature`}
        >
          <Signature />
        </Route>
        <Route
          exact
          path={`${path}/eligible/:privateEquityIdOrSignatureUrl/signature/confirmation`}
        >
          <SignatureConfirmation />
        </Route>
        <Route
          exact
          path={`${path}/eligible/:privateEquityIdOrSignatureUrl/signature/success`}
        >
          <Signature />
        </Route>
        <Route
          exact
          path={`${path}/eligible/:privateEquityIdOrSignatureUrl/signature/fail`}
        >
          <Signature />
        </Route>
        <Route
          exact
          path={`${path}/eligible/:privateEquityIdOrSignatureUrl/signature/cancel`}
        >
          <Signature />
        </Route>
        {/* Page Not Found */}
        <Route>
          <Redirect to="/privateEquity" />
        </Route>
      </Switch>
      <Dialog
        aria-labelledby="dialog-erreur-formulaire"
        onClose={handleCloseDialog}
        open={apiRequestState === ApiRequestState.ERROR}
      >
        <DialogContent dividers id="dialog-erreur-formulaire">
          <Typography align="center">
            <WarningRounded color="error" fontSize="large" />
          </Typography>
          <Typography align="center" gutterBottom variant="h1">
            <FormattedMessage id="dialogError.title" />
          </Typography>
          <Typography align="center">
            <FormattedMessage id="dialogError.text1" />
          </Typography>
          <Typography align="center">
            <FormattedMessage id="dialogError.text2" />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            <FormattedMessage id="dialogError.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
