import { Box, Button, Link, Grid, Typography, Avatar } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as RightArrow } from "../../../assets/images/righ-arrow.svg";
import { ReactComponent as SeeingLongTerm } from "../../../assets/images/seeing-long-term.svg";
import { ReactComponent as Times } from "../../../assets/images/times.svg";
import { StepBar } from "../../../components/stepper/StepBar";
import { StepperTitle } from "../../../components/stepper/StepperTitle";
import { steps } from "../steps/stepsDefinition";
import { Risks } from "./Risks";
import { WhatIsPrivateEquity } from "./WhatIsPrivateEquity";
import { YomoniAltaroc } from "./YomoniAltaroc";

import { useStyles } from "./Eligibility.style";

export const NotEligible = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: 1,
      }}
    >
      <Box sx={{ gridRow: "1" }}>
        <StepperTitle
          count={Object.keys(steps).length}
          current={Object.keys(steps).length}
          displayCurrentStep={false}
          label={intl.formatMessage({
            id: `privateEquity.result.title`,
          })}
          title={intl.formatMessage({ id: "privateEquity.title" })}
        />
        <StepBar
          count={Object.keys(steps).length}
          current={Object.keys(steps).length - 1}
        />
      </Box>
      <Box sx={{ gridRow: "2" }}>
        <Grid container className={classes.greyPanel}>
          <Grid item xs={12} sm={1}>
            <Avatar
              style={{ height: 36, width: 36 }}
              className={classes.blueBulletPoint}
            >
              <Times alt={""} />
            </Avatar>
          </Grid>
          <Grid item xs={12} sm={11}>
            <Typography align="left" className={classes.bigTitle}>
              <FormattedMessage id="privateEquity.notEligible.bookAppointmentTitle" />
            </Typography>
            <Grid container className={classes.subSection}>
              <Grid item xs={12} sm={8}>
                <Typography align="left" className={classes.bigContent}>
                  <FormattedMessage id="privateEquity.notEligible.needClarification" />
                </Typography>
                <Typography align="left" className={classes.bigContent}>
                  <FormattedMessage id="privateEquity.notEligible.bookAppointmentContent" />
                </Typography>
                <Box mt={2} pt={3}>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    href="https://calendly.com/yomoni-conseillers-prives/entretien-avec-un-conseiller-prive-yomoni-private-equity?utm_source=site_statique&utm_medium=onsite&utm_campaign=per-private-equity&month=2022-09"
                    target={"_blank"}
                  >
                    <FormattedMessage id="privateEquity.notEligible.bookAppointmentButtonLabel" />
                  </Button>
                </Box>
                <Typography align="left" className={classes.smallNote}>
                  <FormattedMessage id="privateEquity.notEligible.appointmentDurationDetails" />
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Box spacing={2} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                  }}
                  className={classes.seeingLongTerm}
                >
                  <SeeingLongTerm
                    title={intl.formatMessage({
                      id: "privateEquity.notEligible.seeingLongTermImage",
                    })}
                  />
                </Box>
              </Grid>
            </Grid>
            <WhatIsPrivateEquity />
            <YomoniAltaroc />
            <Risks />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ gridRow: "3" }}>
        <Typography align="center" className={classes.hugeTitle}>
          <FormattedMessage id="privateEquity.notEligible.otherOptionsTitle" />
        </Typography>
      </Box>
      <Box sx={{ gridRow: "4" }}>
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid item xs={12} sm={6}>
            <Link
              href={"https://app.yomoni.fr/#/souscription/analyse"}
              target={"_self"}
              className={classes.otherOptionLink}
            >
              <Box className={classes.otherOptionSection}>
                <Box className={classes.otherOptionArrow}>
                  <RightArrow
                    style={{
                      width: 14,
                    }}
                    alt={""}
                  />
                </Box>
                <Typography align="left" className={classes.otherOptionTitle}>
                  <FormattedMessage id="privateEquity.notEligible.investmentProjectTitle" />
                </Typography>
                <Typography className={classes.otherOptionContent} align="left">
                  <FormattedMessage id="privateEquity.notEligible.investmentProjectContent" />
                </Typography>
              </Box>
            </Link>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Link
              href={
                "https://retraite.yomoni.fr/souscription/per/projet/projet-investissement"
              }
              target={"_self"}
              className={classes.otherOptionLink}
            >
              <Box className={classes.otherOptionSection}>
                <Box className={classes.otherOptionArrow}>
                  <RightArrow
                    style={{
                      width: 14,
                    }}
                    alt={""}
                  />
                </Box>
                <Typography align="left" className={classes.otherOptionTitle}>
                  <FormattedMessage id="privateEquity.notEligible.retirementProjectTitle" />
                </Typography>
                <Typography align="left" className={classes.otherOptionContent}>
                  <FormattedMessage id="privateEquity.notEligible.retirementProjectContent" />
                </Typography>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ gridRow: "5" }} className={classes.lastSection} />
    </Box>
  );
};
