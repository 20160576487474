import { ExpandMoreRounded } from "@material-ui/icons";

import { palette } from "../palette";

export const MuiSelect = {
  overrides: {
    select: {
      "&$select": { paddingRight: "36px" },
      "&:focus": {
        backgroundColor: "#fff",
      },
      "&$disabled": {
        cursor: "not-allowed",
      },
      "&$disabled::-ms-value": {
        color: palette.text.disabled, // IE fix
      },
    },
    icon: {
      marginRight: "8px",
    },
  },
  props: {
    IconComponent: ExpandMoreRounded,
    //native: true,
  },
};
