import { FormLabel, RadioGroup } from "@material-ui/core";

import { RadioCard } from "../components/radio-card/RadioCard";

export const RadioWidget = ({
  id,
  schema,
  options,
  value,
  required,
  disabled,
  readonly,
  label,
  onChange,
  onBlur,
  onFocus,
}) => {
  const { enumOptions, enumDisabled } = options;

  const _onChange = (event, value) =>
    onChange(schema.type === "boolean" ? value !== "false" : value);
  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  const _onFocus = ({ target: { value } }) => onFocus(id, value);

  const row = options && options.inline ? options.inline : false;

  return (
    <>
      <FormLabel required={required} htmlFor={id}>
        {label || schema.title}
      </FormLabel>
      <RadioGroup
        // Fix "uncontrolled" to "controlled" error
        value={typeof value === "undefined" ? "" : value}
        row={row}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
      >
        {enumOptions.map((option, i) => {
          const itemDisabled =
            enumDisabled && enumDisabled.indexOf(option.value) !== -1;

          const radio = (
            <RadioCard
              key={i}
              value={option.value}
              disabled={disabled || itemDisabled || readonly}
            >
              {option.label}
            </RadioCard>
          );

          return radio;
        })}
      </RadioGroup>
    </>
  );
};
