import API from "@aws-amplify/api";
import { v4 as uuidv4 } from "uuid";

import { configuration as config } from "../../config";
import { createCookie, getCookie } from "../../utils/cookies";
import {
  IDENTIFY_USER,
  TRACK_ELIGIBILITY,
  TRACK_SIGNATURE,
} from "../cdp/actions";

const createCDPCookie = () => {
  const cdpCookie = { anonymousId: uuidv4() };
  createCookie(
    config.cdpCookieName,
    JSON.stringify(cdpCookie),
    365,
    config.domain
  );
  return cdpCookie;
};

const getAnonymousId = () => {
  let cdpCookie = {};
  let rawcookie = getCookie(config.cdpCookieName);
  if (rawcookie) {
    try {
      cdpCookie = JSON.parse(rawcookie);
      if (!cdpCookie || !cdpCookie.anonymousId) {
        cdpCookie = createCDPCookie();
      }
    } catch (e) {
      cdpCookie = createCDPCookie();
    }
  } else {
    cdpCookie = createCDPCookie();
  }
  return cdpCookie.anonymousId;
};

export const createEvent = (action) => {
  let event;
  switch (action.type) {
    case IDENTIFY_USER:
      event = {
        type: "identify",
        traits: action.payload.privateEquity,
        anonymousId: getAnonymousId(),
      };
      break;
    case TRACK_ELIGIBILITY:
      event = {
        anonymousId: getAnonymousId(),
        type: "track",
        event: "Eligibilité Private Equity",
        traits: {
          email: action.payload.eligible.email,
        },
        properties: action.payload.eligible,
      };
      break;
    case TRACK_SIGNATURE:
      event = {
        anonymousId: getAnonymousId(),
        type: "track",
        event: "Signature Private Equity",
        traits: {
          signatureUrl: action.payload.signature.signatureUrl,
        },
        properties: action.payload.signature,
      };
      break;
    default:
      event = null;
      break;
  }

  return event;
};

export const cdpMiddleware = () => (next) => async (action) => {
  const event = createEvent(action);
  if (event) {
    API.post("Cdp", "/cdp", {
      body: event,
    });
  }
  return next(action);
};
