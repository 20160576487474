import { Button, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as IntroPrivateEquity } from "../../../assets/images/introPrivateEquity.svg";

import { useStyles } from "./StepIntro.style.js";

export const StepIntro = (props) => {
  const { onSubmit } = props;
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.picture}>
        <IntroPrivateEquity
          title={intl.formatMessage({ id: "privateEquity.intro.title" })}
        />
      </div>
      <div className={classes.title}>
        <Typography align="center" variant="h1">
          <FormattedMessage id="privateEquity.intro.title" />
        </Typography>
      </div>
      <div className={classes.content}>
        <Typography align="center" variant="subtitle1">
          <FormattedMessage id="privateEquity.intro.subtitle1" />
          <br />
          <FormattedMessage id="privateEquity.intro.subtitle2" />
        </Typography>
      </div>
      <div className={classes.actions}>
        <Button
          color="primary"
          onClick={onSubmit}
          size="large"
          variant="contained"
        >
          <FormattedMessage id="privateEquity.intro.next" />
        </Button>
      </div>
    </div>
  );
};

StepIntro.propTypes = {
  onSubmit: PropTypes.func,
};
