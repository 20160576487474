import API from "@aws-amplify/api";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  matchPath,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { LoadingModal } from "../../components/loading-modal/LoadingModal";
import { StepBar } from "../../components/stepper/StepBar";
import { StepperTitle } from "../../components/stepper/StepperTitle";
import { resetForm, updateForm } from "../../redux/actions";
import { getForm } from "../../redux/selectors";
import { addErrorMessages } from "../../utils/form";
import { camelize } from "../../utils/string";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";
import { Step5 } from "./Step5";
import { Step6 } from "./Step6";
import { Step7 } from "./Step7";
import { StepConfirmation } from "./StepConfirmation";
import { StepIntro } from "./StepIntro";

import { useStyles } from "./Form360.style.js";

const StepNames = {
  STEP_1: "ETAPE_1",
  STEP_2: "ETAPE_2",
  STEP_3: "ETAPE_3",
  STEP_4: "ETAPE_4",
  STEP_5: "ETAPE_5",
  STEP_6: "ETAPE_6",
  STEP_7: "ETAPE_7",
};

const steps = [
  {
    component: Step1,
    name: StepNames.STEP_1,
    path: "etape-1",
  },
  {
    component: Step2,
    name: StepNames.STEP_2,
    path: "etape-2",
  },
  {
    component: Step3,
    name: StepNames.STEP_3,
    path: "etape-3",
  },
  {
    component: Step4,
    name: StepNames.STEP_4,
    path: "etape-4",
  },
  {
    component: Step5,
    name: StepNames.STEP_5,
    path: "etape-5",
  },
  {
    component: Step6,
    name: StepNames.STEP_6,
    path: "etape-6",
  },
  {
    component: Step7,
    name: StepNames.STEP_7,
    path: "etape-7",
  },
];

addErrorMessages("format", "error.invalid");
addErrorMessages("maxItems", "error.array.max");
addErrorMessages("maxLength", "error.string.max");
addErrorMessages("maximum", "error.number.max");
addErrorMessages("minItems", "error.required");
addErrorMessages("minLength", "error.string.min");
addErrorMessages("minimum", "error.number.min");
addErrorMessages("pattern", "error.string.invalidCharacters");
addErrorMessages("required", "error.required");

const FORM_NAME = "360";

const ApiRequestState = {
  NONE: "NONE",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

const useSaveRedirectURLInStorage = (location) => {
  useEffect(() => {
    const redirectUrl = new URLSearchParams(location.search).get("redirectUrl");
    if (redirectUrl) {
      window.localStorage.setItem("redirectUrl", redirectUrl);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const Form360 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const [apiRequestState, setApiRequestState] = useState(ApiRequestState.NONE);
  const { path, url } = useRouteMatch();
  const { params } = matchPath(location.pathname, {
    path: `${url}/:step?`,
    exact: true,
    strict: false,
  });
  const currentStepPath = params.step || "";
  const activeStepIndex = steps.findIndex(
    ({ path: stepPath }) => stepPath === currentStepPath
  );
  const { data: formData, props: formProps } = useSelector(getForm(FORM_NAME));
  const classes = useStyles();
  useSaveRedirectURLInStorage(location);

  // Redirect to base URL if no persisted form data or step URL too far
  useEffect(() => {
    if (params.step === "confirmation") {
      if (apiRequestState !== ApiRequestState.SUCCESS) {
        history.replace(url);
      }
      return;
    }

    if (
      !formData ||
      (formProps &&
        (formProps.step === undefined || activeStepIndex > formProps.step))
    ) {
      history.replace(url);
      return;
    }
  }, [
    activeStepIndex,
    formData,
    formProps,
    history,
    apiRequestState,
    params.step,
    url,
  ]);

  const handleChange = (values) => {
    // Ignore changes if not in step
    if (activeStepIndex === -1) {
      return;
    }

    dispatch(updateForm(FORM_NAME, values.formData));
  };

  const handlePrevious = () => {
    dispatch(updateForm(FORM_NAME, null, { step: activeStepIndex - 1 }));

    if (activeStepIndex === 0) {
      history.push(url);
    } else {
      const stepPath = steps[activeStepIndex - 1].path;
      history.push(`${url}${stepPath ? "/" + stepPath : ""}`);
    }
  };

  const handleSubmit = (values) => {
    if (
      apiRequestState === ApiRequestState.PENDING ||
      apiRequestState === ApiRequestState.SUCCESS
    ) {
      return;
    }

    // Intermediate step
    if (activeStepIndex + 1 < steps.length) {
      dispatch(
        updateForm(FORM_NAME, values.formData, { step: activeStepIndex + 1 })
      );
      // Redirect to the next step
      const stepPath = steps[activeStepIndex + 1].path;
      history.push(`${url}${stepPath ? "/" + stepPath : ""}`);
    }

    // Final step
    if (activeStepIndex + 1 === steps.length) {
      dispatch(updateForm(FORM_NAME, values.formData));

      setApiRequestState(ApiRequestState.PENDING);
      API.post("Opportunites", "/opportunites", { body: values.formData })
        .then(() => {
          setApiRequestState(ApiRequestState.SUCCESS);
          history.push(`${url}/confirmation`);
          // Reset form data after redirection
          dispatch(resetForm(FORM_NAME));
        })
        .catch((error) => {
          setApiRequestState(ApiRequestState.ERROR);
        });
    }
  };

  const handleCloseDialog = () => {
    setApiRequestState(ApiRequestState.NONE);
  };

  return (
    <Grid
      alignItems="center"
      className={classes.root}
      container
      justifyContent={"center"}
    >
      <LoadingModal open={apiRequestState === ApiRequestState.PENDING} />
      {activeStepIndex >= 0 && (
        <Grid item xs={12}>
          <StepperTitle
            count={Object.keys(steps).length}
            current={activeStepIndex + 1}
            label={intl.formatMessage({
              id: `360.${camelize(
                steps[activeStepIndex].name.toLowerCase()
              )}.titre`,
            })}
            title={intl.formatMessage({ id: "360.titre" })}
          />
          <StepBar
            count={Object.keys(steps).length}
            current={activeStepIndex}
          />
        </Grid>
      )}
      <Switch>
        <Route exact path={`${path}`}>
          <StepIntro onSubmit={handleSubmit} />
        </Route>
        {formData &&
          steps.map(({ component: Component, name, path: stepPath }) => (
            <Route
              exact
              key={name}
              path={`${path}${stepPath ? "/" + stepPath : ""}`}
            >
              <Component
                formData={formData}
                onChange={handleChange}
                onPrevious={handlePrevious}
                onSubmit={handleSubmit}
              />
            </Route>
          ))}
        <Route exact path={`${path}/confirmation`}>
          <StepConfirmation formData={formData} />
        </Route>
        {/* Page Not Found */}
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
      <Dialog
        aria-labelledby="dialog-erreur-formulaire"
        onClose={handleCloseDialog}
        open={apiRequestState === ApiRequestState.ERROR}
      >
        <DialogContent dividers id="dialog-erreur-formulaire">
          <Typography align="center">
            <WarningRounded color="error" fontSize="large" />
          </Typography>
          <Typography align="center" gutterBottom variant="h1">
            <FormattedMessage id="dialogError.title" />
          </Typography>
          <Typography align="center">
            <FormattedMessage id="dialogError.text1" />
          </Typography>
          <Typography align="center">
            <FormattedMessage id="dialogError.text2" />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            <FormattedMessage id="dialogError.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
