import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Form } from "../../../components/form/Form";

const getSchema = (values, intl) => {
  return {
    type: "object",
    required: ["riskTolerance1"],
    properties: {
      riskTolerance1: {
        type: "string",
        title: intl.formatMessage({
          id: "privateEquity.step15.riskTolerance1",
        }),
        oneOf: [
          {
            title: intl.formatMessage({ id: "riskTolerance1.no" }),
            const: "no",
          },
          {
            title: intl.formatMessage({ id: "riskTolerance1.tenMax" }),
            const: "tenMax",
          },
          {
            title: intl.formatMessage({ id: "riskTolerance1.twentyMax" }),
            const: "twentyMax",
          },
          {
            title: intl.formatMessage({ id: "riskTolerance1.moreThanTwenty" }),
            const: "moreThanTwenty",
          },
        ],
      },
    },
  };
};

const uiSchema = {
  "ui:order": ["riskTolerance1"],
  riskTolerance1: {
    "ui:widget": "radio",
  },
};

export const Step15 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
      />
    </Slide>
  );
};

Step15.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
