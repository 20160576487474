const createPayloadAction = (type, payload, meta, error) => ({
  type,
  payload,
  meta,
  error,
});

/**
 * Factory method to create reducer.
 *
 * @param {Object} initialState - The initial state
 * @param {Object} handlers - The list of action handlers
 * @return {Function} The reducer
 */
const createReducer = (initialState, handlers) => {
  return (state = initialState, action) => {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
};

export { createPayloadAction, createReducer };
