import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "inline-flex",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
    },
    fullWidth: { width: "100%" },
    paper: {
      padding: theme.spacing(4),
    },
    paperUpload: {
      color: theme.palette.primary.main,
      fontSize: "1.4rem",
      textAlign: "center",
      cursor: "pointer",
    },
    paperUploaded: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    paperError: {
      borderColor: theme.palette.error.main,
      borderStyle: "solid",
      borderWidth: "1px",
    },
    input: {
      display: "none",
    },
    uploadIcon: {
      marginBottom: theme.spacing(2),
    },
    acceptedFormats: {
      fontStyle: "italic",
      fontWeight: theme.typography.fontWeightLight,
    },
    infoDetailsBox: {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      paddingBottom: theme.spacing(2),
    },
    infoDetailsText: {
      color: theme.palette.primary.main,
      fontSize: "1.4rem",
      fontWeight: theme.typography.fontWeightRegular,
      paddingLeft: theme.spacing(1),
    },
    infoDetailsIcon: {
      color: theme.palette.primary.main,
    },
    checkIcon: {
      color: theme.palette.success.main,
      fontSize: "inherit",
    },
    fileIcon: {
      marginRight: theme.spacing(2),
    },
    fileInfo: {
      // Fix truncated text (filename) in a flex element's child
      minWidth: 0,
      flex: 1,
    },
    fileName: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    fileSize: {
      fontSize: "1.2rem",
    },
    loader: {
      marginTop: theme.spacing(2),
    },
    dialogPaper: {
      padding: 0,
    },
    dialogPdfPaper: {
      width: "50vw",
      height: "80vh",
      [theme.breakpoints.up("sm")]: {
        width: "90vw",
        height: "90vh",
      },
    },
    image: {
      width: "100%",
      height: "100%",
    },
  }),
  { name: "FileUpload" }
);
