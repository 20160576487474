import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { breakpoints } from "../../../assets/styles/themes/breakpoints";
import { palette } from "../../../assets/styles/themes/palette";
import { typography } from "../../../assets/styles/themes/typography";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%", // Fix IE 11 to prevent content overlapping
    },
    picture: {
      maxWidth: "52rem",
      paddingBottom: theme.spacing(4),
    },
    greyPanel: {
      width: "100%", // Fix IE 11 to prevent content overlapping
      backgroundColor: palette.blue["100"],
      borderRadius: 8,
      padding: theme.spacing(4, 3),
    },
    bluePanel: {
      width: "100%", // Fix IE 11 to prevent content overlapping
      backgroundColor: palette.primary.main,
      borderRadius: 8,
      padding: theme.spacing(3),
    },
    mainSection: {
      paddingTop: theme.spacing(6),
    },
    bigSection: {
      paddingTop: theme.spacing(5),
    },
    subSection: {
      paddingTop: theme.spacing(3),
    },
    smallSection: {
      padding: theme.spacing(1, 0),
    },
    lastSection: {
      paddingTop: theme.spacing(8),
    },
    otherOptionSection: {
      backgroundColor: palette.blue["100"],
      borderRadius: 8,
      padding: theme.spacing(2),
      height: "100%",
      "&:hover": {
        boxShadow: "inset 0px 0px 0px 2px " + palette.primary.main,
      },
    },
    otherOptionContent: {
      color: palette.primary.main,
      paddingTop: theme.spacing(1),
      fontSize: typography.fontSizeSmall,
      fontWeight: typography.fontWeightLight,
      paddingBottom: theme.spacing(3),
    },
    signatureSection: {
      height: "78vh",
      [theme.breakpoints.up("sm")]: {
        height: "90vh",
      },
    },
    otherOptionArrow: {
      float: "right",
      display: "flex",
      height: "100%",
      alignItems: "flex-end",
    },
    fundsInfoDivider: {
      borderBottom: "thin solid " + alpha(palette.secondary.contrastText, 0.2),
      paddingTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    hugeTitle: {
      color: palette.primary.main,
      fontSize: typography.fontSizeHuge,
      fontWeight: typography.fontWeightMedium,
      padding: theme.spacing(5, 0, 2, 0),
    },
    bigTitle: {
      color: palette.primary.main,
      fontSize: typography.fontSizeBig,
      fontWeight: typography.fontWeightMedium,
    },
    bigTitleSecondary: {
      color: palette.secondary.main,
      fontSize: typography.fontSizeBig,
      fontWeight: typography.fontWeightMedium,
    },
    bigContent: {
      color: palette.primary.main,
      paddingTop: theme.spacing(1),
      fontSize: typography.fontSizeNormal,
      fontWeight: typography.fontWeightLight,
    },
    bigContentSecondary: {
      color: palette.secondary.contrastText,
      paddingTop: theme.spacing(1),
      fontSize: typography.fontSizeNormal,
      fontWeight: typography.fontWeightLight,
    },
    hugeContentSecondary: {
      color: palette.secondary.contrastText,
      fontSize: "3rem",
      fontWeight: typography.fontWeightMedium,
    },
    mediumTitle: {
      color: palette.primary.main,
      fontSize: typography.fontSizeNormal,
      fontWeight: typography.fontWeightMedium,
    },
    mediumTitleSecondary: {
      color: palette.secondary.contrastText,
      fontSize: typography.fontSizeNormal,
      fontWeight: typography.fontWeightMedium,
    },
    mainRiskTitle: {
      color: palette.primary.main,
      fontSize: typography.fontSizeSmall,
      fontWeight: typography.fontWeightMedium,
      padding: theme.spacing(0.5, 0, 2, 0),
    },
    riskTitle: {
      color: palette.primary.main,
      fontSize: typography.fontSizeSmall,
      fontWeight: typography.fontWeightMedium,
      padding: theme.spacing(0.5, 0, 0, 0),
    },
    functioningTitle: {
      color: palette.primary.main,
      fontSize: typography.fontSizeMedium,
      fontWeight: typography.fontWeightMedium,
      padding: theme.spacing(1, 0, 0, 0),
    },
    functioningSubTitle: {
      color: palette.secondary.main,
      fontSize: typography.fontSizeSmall,
      fontWeight: typography.fontWeightRegular,
      padding: theme.spacing(0.5, 0, 0, 0),
    },
    howToSubscribeTitle: {
      color: palette.primary.main,
      fontSize: typography.fontSizeMedium,
      fontWeight: typography.fontWeightMedium,
      padding: theme.spacing(0.5, 0, 0, 0),
    },
    otherOptionTitle: {
      color: palette.primary.main,
      fontSize: typography.fontSizeNormal,
      fontWeight: typography.fontWeightMedium,
    },
    mediumContent: {
      color: palette.primary.main,
      paddingTop: theme.spacing(1),
      fontSize: typography.fontSizeSmall,
      fontWeight: typography.fontWeightLight,
    },
    mediumContentSecondary: {
      color: palette.secondary.contrastText,
      paddingTop: theme.spacing(1),
      fontSize: typography.fontSizeSmall,
      fontWeight: typography.fontWeightLight,
    },
    fundsInfoRowHeader: {
      color: palette.secondary.contrastText,
      paddingTop: theme.spacing(1),
      fontSize: typography.fontSizeSmall,
      fontWeight: typography.fontWeightLight,
      [`@media only screen and (min-width: ${breakpoints.values.sm}px)`]: {
        paddingLeft: theme.spacing(2),
      },
    },
    howToSubscribeItem: {
      maxWidth: "52px",
    },
    boldMediumContentSecondary: {
      color: palette.secondary.contrastText,
      paddingTop: theme.spacing(1),
      fontSize: typography.fontSizeSmall,
      fontWeight: typography.fontWeightMedium,
      [`@media only screen and (min-width: ${breakpoints.values.sm}px)`]: {
        textAlign: "right",
      },
      [`@media only screen and (max-width: ${breakpoints.values.sm}px)`]: {
        textAlign: "left",
      },
    },
    mediumLink: {
      color: palette.primary.main,
      fontSize: typography.fontSizeSmall,
      fontWeight: typography.fontWeightMedium,
    },
    mediumLinkSecondary: {
      color: palette.secondary.contrastText,
      fontSize: typography.fontSizeSmall,
      fontWeight: typography.fontWeightMedium,
    },
    otherOptionLink: {
      "&:hover": {
        textDecoration: "none",
      },
    },
    smallNote: {
      color: palette.primary.main,
      paddingTop: theme.spacing(1),
      fontSize: typography.fontSizeTiny,
      fontStyle: "italic",
      fontWeight: typography.fontWeightLight,
    },
    tinyNote: {
      color: palette.primary.main,
      paddingTop: theme.spacing(2),
      fontSize: typography.fontSizeTiny,
      fontStyle: "italic",
      fontWeight: typography.fontWeightLight,
    },
    enterprisesNote: {
      color: palette.primary.main,
      paddingTop: theme.spacing(2),
      fontSize: typography.fontSizeNano,
      fontStyle: "italic",
      fontWeight: typography.fontWeightLight,
    },
    blueBulletPoint: {
      backgroundColor: palette.primary.main,
    },
    greenBulletPoint: {
      backgroundColor: palette.success.main,
    },
    whiteBulletPoint: {
      backgroundColor: palette.backgroundColor.default,
    },
    greyBulletPoint: {
      backgroundColor: palette.blue["100"],
      color: palette.primary.main,
    },
    boxPadding: {
      padding: theme.spacing(3, 0),
    },
    seeingLongTerm: {
      [`@media only screen and (max-width: ${breakpoints.values.sm}px)`]: {
        display: "none",
      },
    },
    yomoniAltarocInline: {
      [`@media only screen and (min-width: ${breakpoints.values.sm}px)`]: {
        display: "none",
      },
    },
    yomoniAltarocColumn: {
      [`@media only screen and (max-width: ${breakpoints.values.sm}px)`]: {
        display: "none",
      },
    },
    curvedArrow: {
      [`@media only screen and (max-width: ${breakpoints.values.sm}px)`]: {
        display: "none",
      },
    },
    functioningChart: {
      [`@media only screen and (max-width: ${breakpoints.values.sm}px)`]: {
        display: "none",
      },
    },
    boldText: {
      fontWeight: typography.fontWeightMedium,
    },
  }),
  { name: "Eligibility" }
);
