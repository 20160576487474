import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: "100%",
      width: "100%",
    },
    iframe: {
      height: "45rem",
      width: "100%",
      border: "none",
    },
    loadingWrapper: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: "100%",
    },
  }),
  {
    name: "UniversignSignature",
  }
);
