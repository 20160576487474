import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          alignItems: "flex-start",
        },
      },
      stepper: {
        color: theme.palette.text.light,
        fontSize: "1.4rem",
        letterSpacing: "0.04rem",
        padding: "0 1rem",
        borderLeft: `solid 1px ${theme.palette.text.light}`,
        marginLeft: "1rem",
        [theme.breakpoints.down("sm")]: {
          border: "none",
          marginLeft: 0,
          paddingLeft: 0,
        },
      },
    };
  },
  { name: "StepperTitle" }
);
