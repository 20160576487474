import { CircularProgress as CircularProgressBase } from "@material-ui/core";
import cx from "classnames";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import { useStyles } from "./CircularProgress.style";

export const CircularProgress = forwardRef((props, ref) => {
  const { ContainerProps = {}, ...other } = props;

  const classes = useStyles(props);

  return (
    <div
      {...ContainerProps}
      className={cx(classes.container, ContainerProps.className)}
      ref={ref}
    >
      <CircularProgressBase
        className={classes.top}
        size={48}
        thickness={4}
        {...other}
        // cannot be overridden by props
        variant="determinate"
        value={100}
      />
      <CircularProgressBase
        className={classes.bottom}
        size={48}
        thickness={4}
        {...other}
      />
    </div>
  );
});

CircularProgress.propTypes = {
  ContainerProps: PropTypes.object,
  className: PropTypes.string,
};
