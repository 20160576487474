const DATA_URL_REGEX = new RegExp(
  "^data:(?:([a-z]+/[a-z\\-.+]+)((?:;[^=;,]+=[^=;,]+)*))?(?:;base64)?,([A-Za-z0-9!$&',()*+,;=\\-._~:@/?%s]*)$"
);
const NAME_KEY = "name";
const SIZE_KEY = "size";

const convertBase64toBlob = (data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const extractFileFromDataUrl = (value) => {
  if (typeof value !== "string") {
    return;
  }

  const result = DATA_URL_REGEX.exec(value);
  if (!result) {
    return;
  }

  const params = {};
  if (result[2]) {
    result[2]
      .slice(1) // Remove first semi colon
      .split(";")
      .forEach((param) => {
        const [key, value] = param.split("=");
        params[key] = decodeURIComponent(value);
      });
  }

  const type = result[1];
  const content = result[3];
  const blob = convertBase64toBlob(content, type, 1024);
  const url = URL.createObjectURL(blob);

  const size = Number(params[SIZE_KEY]);
  return {
    content,
    name: params[NAME_KEY],
    size: Number.isNaN(size) ? undefined : size,
    type,
    url,
  };
};

export const convertFileToDataUrl = (file, base64 = false) => {
  if (!file) {
    return;
  }

  const { content, name, size, type } = file;

  const params = Object.entries({ name, size })
    .map(([key, value]) => {
      if (!key || !value) {
        return "";
      }

      return `${key}=${encodeURIComponent(value)}`;
    })
    .filter(Boolean)
    .join(";");

  return `data:${type}${params ? ";" + params : ""}${
    base64 ? ";base64" : ""
  },${content}`;
};
