import { frFR } from "@material-ui/core/locale";
import { createTheme } from "@material-ui/core/styles";

import { breakpoints } from "./breakpoints";
import {
  MuiButton,
  MuiButtonBase,
  MuiChip,
  MuiCssBaseline,
  MuiFormControl,
  MuiFormGroup,
  MuiFormHelperText,
  MuiFormLabel,
  MuiInput,
  MuiInputLabel,
  MuiInputAdornment,
  MuiSelect,
  MuiTextField,
  MuiTooltip,
} from "./components-overrides";
import { palette } from "./palette";
import { shape } from "./shape";
import { spacing } from "./spacing";
import { typography } from "./typography";

export const theme = createTheme(
  {
    breakpoints,
    overrides: {
      MuiButton: MuiButton.overrides,
      MuiChip: MuiChip.overrides,
      MuiCssBaseline: MuiCssBaseline.overrides,
      MuiFormControl: MuiFormControl.overrides,
      MuiFormHelperText: MuiFormHelperText.overrides,
      MuiFormLabel: MuiFormLabel.overrides,
      MuiInput: MuiInput.overrides,
      MuiInputLabel: MuiInputLabel.overrides,
      MuiInputAdornment: MuiInputAdornment.overrides,
      MuiSelect: MuiSelect.overrides,
      MuiTextField: MuiTextField.overrides,
    },
    palette,
    props: {
      MuiButton: MuiButton.props,
      MuiButtonBase: MuiButtonBase.props,
      MuiFormGroup: MuiFormGroup.props,
      MuiSelect: MuiSelect.props,
      MuiTextField: MuiTextField.props,
      MuiTooltip: MuiTooltip.props,
    },
    shape,
    spacing,
    typography,
  },
  frFR
);
