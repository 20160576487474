import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Form } from "../../../components/form/Form";

const getSchema = (values, intl) => {
  return {
    type: "object",
    required: ["fundsOrigin"],
    properties: {
      fundsOrigin: {
        type: "string",
        format: "data-url",
        title: intl.formatMessage({ id: "privateEquity.step18.fundsOrigin" }),
      },
    },
  };
};

export const Step18 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  const uiSchema = {
    "ui:order": ["fundsOrigin"],
    fundsOrigin: {
      "ui:options": {
        accept: ".jpeg,.jpg,.gif,.png,.pdf",
        infoDetails: intl.formatMessage({
          id: "privateEquity.step18.fundsOriginDetails",
        }),
      },
    },
  };

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
      />
    </Slide>
  );
};

Step18.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
