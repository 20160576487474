import { InputAdornment } from "@material-ui/core";
import { useIntl } from "react-intl";

const errorMessages = {};

export const addErrorMessages = (name, messageId) => {
  errorMessages[name] = messageId;
};

export const useTransformErrors = () => {
  const intl = useIntl();
  return (errors) => {
    return errors.map((error) => {
      if (errorMessages.hasOwnProperty(error.name)) {
        error.message = intl.formatMessage(
          { id: errorMessages[error.name] },
          error.params
        );
      }
      return error;
    });
  };
};

export const createNumberOptions = (props) => {
  const {
    adornment,
    maxLength = 10,
    options: optionsProp = {},
    thousandSeparator = " ",
    widget,
  } = props;

  const options = {
    ...optionsProp,
    inputProps: {
      allowNegative: false,
      decimalScale: 0,
      maxLength,
      thousandSeparator,
    },
  };
  if (adornment) {
    options.InputProps = {
      endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>,
    };
  }

  return {
    "ui:widget": widget || "updown",
    "ui:options": options,
  };
};

export const createNumberPatternOptions = (props) => {
  const { displayType = "input", format, options: optionsProp = {} } = props;

  const options = {
    ...optionsProp,
    inputProps: {
      displayType,
      format,
    },
  };

  return {
    "ui:widget": "numberPatternWidget",
    "ui:options": options,
  };
};
