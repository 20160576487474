import { Slide } from "@material-ui/core";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Form } from "../../components/form/Form";
import { createNumberOptions } from "../../utils/form";
import { capitalize } from "../../utils/string";

const getSchema = (values, intl) => {
  const schema = {
    type: "object",
    required: ["accompagnementPatrimonial", "compositionPatrimoineFinancier"],
    properties: {
      valeurPatrimoineFinancier: {
        type: "string",
        title: intl.formatMessage({
          id: "360.etape5.valeurPatrimoineFinancier",
        }),
        oneOf: [
          {
            title: intl.formatMessage({
              id: "valeurPatrimoineFinancier.entre10000Et100000",
            }),
            const: "entre10000Et100000",
          },
          {
            title: intl.formatMessage({
              id: "valeurPatrimoineFinancier.entre100000Et250000",
            }),
            const: "entre100000Et250000",
          },
          {
            title: intl.formatMessage({
              id: "valeurPatrimoineFinancier.entre250000Et1000000",
            }),
            const: "entre250000Et1000000",
          },
          {
            title: intl.formatMessage({
              id: "valeurPatrimoineFinancier.plusDe1000000",
            }),
            const: "plusDe1000000",
          },
        ],
      },
      accompagnementPatrimonial: {
        type: "string",
        title: intl.formatMessage({
          id: "360.etape5.accompagnementPatrimonial",
        }),
        oneOf: [
          {
            title: intl.formatMessage({
              id: "accompagnementPatrimonial.nonPasInteret",
            }),
            const: "nonPasInteret",
          },
          {
            title: intl.formatMessage({
              id: "accompagnementPatrimonial.nonPasEncore",
            }),
            const: "nonPasEncore",
          },
          {
            title: intl.formatMessage({
              id: "accompagnementPatrimonial.ouiPasSatisfait",
            }),
            const: "ouiPasSatisfait",
          },
          {
            title: intl.formatMessage({
              id: "accompagnementPatrimonial.ouiBesoinExpertise",
            }),
            const: "ouiBesoinExpertise",
          },
        ],
      },
      compositionPatrimoineFinancier: {
        type: "array",
        title: intl.formatMessage({
          id: "360.etape5.compositionPatrimoineFinancier",
        }),
        minItems: 1,
        uniqueItems: true,
        items: {
          type: "string",
          oneOf: [
            {
              title: intl.formatMessage({
                id: "supportFinancier.comptesCourants",
              }),
              const: "comptesCourants",
            },
            {
              title: intl.formatMessage({
                id: "supportFinancier.livrets",
              }),
              const: "livrets",
            },
            {
              title: intl.formatMessage({
                id: "supportFinancier.pel",
              }),
              const: "pel",
            },
            {
              title: intl.formatMessage({
                id: "supportFinancier.av",
              }),
              const: "av",
            },
            {
              title: intl.formatMessage({
                id: "supportFinancier.pea",
              }),
              const: "pea",
            },
            {
              title: intl.formatMessage({
                id: "supportFinancier.cto",
              }),
              const: "cto",
            },
            {
              title: intl.formatMessage({
                id: "supportFinancier.epargneSalariale",
              }),
              const: "epargneSalariale",
            },
            {
              title: intl.formatMessage({
                id: "supportFinancier.perp",
              }),
              const: "perp",
            },
            {
              title: intl.formatMessage({
                id: "supportFinancier.article83",
              }),
              const: "article83",
            },
            {
              title: intl.formatMessage({
                id: "supportFinancier.per",
              }),
              const: "per",
            },
            {
              title: intl.formatMessage({
                id: "supportFinancier.madelin",
              }),
              const: "madelin",
            },
            {
              title: intl.formatMessage({
                id: "supportFinancier.aga",
              }),
              const: "aga",
            },
            {
              title: intl.formatMessage({
                id: "supportFinancier.autres",
              }),
              const: "autres",
            },
          ],
        },
      },
    },
  };

  if (values) {
    if (
      ["ouiPasSatisfait", "ouiBesoinExpertise"].includes(
        values.accompagnementPatrimonial
      )
    ) {
      schema.properties.typeAccompagnementPatrimonial = {
        type: "string",
        title: intl.formatMessage({
          id: "360.etape5.typeAccompagnementPatrimonial",
        }),
        oneOf: [
          {
            title: intl.formatMessage({
              id: "typeAccompagnementPatrimonial.gestionConseillee",
            }),
            const: "gestionConseillee",
          },
          {
            title: intl.formatMessage({
              id: "typeAccompagnementPatrimonial.gestionSousMandat",
            }),
            const: "gestionSousMandat",
          },
        ],
      };
      schema.required.push("typeAccompagnementPatrimonial");
    }
    if (values.compositionPatrimoineFinancier) {
      values.compositionPatrimoineFinancier.forEach((type) => {
        schema.properties[type] = {
          type: "number",
          title: intl.formatMessage({
            id: `supportFinancier.montant${capitalize(type)}`,
          }),
        };
      });
      if (values.compositionPatrimoineFinancier.includes("av")) {
        schema.properties.proportionUnitesCompteAv = {
          type: "number",
          title: intl.formatMessage({
            id: "360.etape5.proportionUnitesCompteAv",
          }),
          minimum: 0,
          maximum: 100,
          multipleOf: 10,
          default: 100,
        };
        schema.properties.releveSituationAv = {
          type: "string",
          format: "data-url",
          title: intl.formatMessage({
            id: "360.etape5.releveSituationAv",
          }),
        };
      }
      if (values.compositionPatrimoineFinancier.includes("pea")) {
        schema.properties.dateOuverturePea = {
          type: "integer",
          title: intl.formatMessage({
            id: "360.etape5.dateOuverturePea",
          }),
          minimum: 1992, // Date de création du PEA
          maximum: dayjs().year(),
        };
      }
      if (values.compositionPatrimoineFinancier.includes("epargneSalariale")) {
        schema.properties.epargneSalarialeDisponible = {
          type: "number",
          title: intl.formatMessage({
            id: "360.etape5.epargneSalarialeDisponible",
          }),
        };
      }
    }
  }

  return schema;
};

export const Step5 = (props) => {
  const { formData, onChange, onPrevious, onSubmit } = props;
  const intl = useIntl();

  const uiSchema = useMemo(
    () => ({
      "ui:order": [
        "valeurPatrimoineFinancier",
        "accompagnementPatrimonial",
        "typeAccompagnementPatrimonial",
        "compositionPatrimoineFinancier",
        "comptesCourants",
        "livrets",
        "pel",
        "av",
        "pea",
        "cto",
        "epargneSalariale",
        "perp",
        "article83",
        "per",
        "madelin",
        "aga",
        "autres",
        "proportionUnitesCompteAv",
        "releveSituationAv",
        "dateOuverturePea",
        "epargneSalarialeDisponible",
        "montantDisponibleEpargneSalariale",
      ],
      compositionPatrimoineFinancier: {
        "ui:widget": "checkboxes",
      },
      comptesCourants: createNumberOptions({ adornment: "€" }),
      livrets: createNumberOptions({ adornment: "€" }),
      pel: createNumberOptions({ adornment: "€" }),
      av: createNumberOptions({ adornment: "€" }),
      pea: createNumberOptions({ adornment: "€" }),
      cto: createNumberOptions({ adornment: "€" }),
      epargneSalariale: createNumberOptions({ adornment: "€" }),
      perp: createNumberOptions({ adornment: "€" }),
      article83: createNumberOptions({ adornment: "€" }),
      per: createNumberOptions({ adornment: "€" }),
      madelin: createNumberOptions({ adornment: "€" }),
      aga: createNumberOptions({ adornment: "€" }),
      autres: createNumberOptions({ adornment: "€" }),
      releveSituationAv: {
        "ui:options": { accept: "image/*,.pdf" },
      },
      proportionUnitesCompteAv: {
        "ui:widget": "range",
        "ui:options": {
          marks: [
            {
              value: 0,
              label: "0",
            },
            {
              value: 100,
              label: "100",
            },
          ],
          valueLabelDisplay: "on",
          valueLabelFormat: (number) => `${number}%`,
        },
      },
      dateOuverturePea: createNumberOptions({
        maxLength: 4,
        thousandSeparator: "",
        options: {
          helperText: intl.formatMessage({
            id: "360.etape5.dateOuverturePea.description",
          }),
        },
      }),
      epargneSalarialeDisponible: createNumberOptions({
        adornment: "€",
        options: {
          helperText: intl.formatMessage({
            id: "360.etape5.epargneSalarialeDisponible.description",
          }),
        },
      }),
    }),
    [intl]
  );

  return (
    <Slide direction="up" in>
      <Form
        formData={formData}
        noHtml5Validate
        onChange={onChange}
        onPrevious={onPrevious}
        onSubmit={onSubmit}
        schema={getSchema(formData, intl)}
        showErrorList={false}
        uiSchema={uiSchema}
      />
    </Slide>
  );
};

Step5.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};
