/**
 * Convert string to camel case.
 *
 * @param {string} string - The string
 * @returns The string in camel case
 */
export const camelize = (string) => {
  return string.replace(/^([A-Z])|[\s-_](\w)/g, (match, p1, p2, offset) => {
    if (p2) {
      return p2.toUpperCase();
    }
    return p1.toLowerCase();
  });
};

export const capitalize = (s) => {
  if (typeof s !== "string") {
    return "";
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const generateId = (options = {}) => {
  const { length = 7, prefix } = options;
  if (prefix !== undefined && typeof prefix !== "string") {
    throw new TypeError("prefix must be a string");
  }
  if (length !== undefined && typeof length !== "number") {
    throw new TypeError("length must be a number");
  }

  let random = "";
  while (random.length < length) {
    random += Math.random().toString(36).substr(2);
  }
  random = random.substr(0, length);

  return prefix ? `${prefix}-${random}` : random;
};
