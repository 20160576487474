import throttle from "lodash/throttle";
import lzstring from "lz-string";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { createStorage } from "../utils/localStorage";
import { cdpMiddleware } from "./middleware/cdpMiddleware";
import { formsReducer } from "./reducers";

const PERSIST_KEY = "yo-forms";

/**
 * Put the object in the local storage by using a LZ-based compression algorithm.
 *
 * @param {object} object - The object
 * @returns {string} The compressed object
 */
const compressObject = (object) => {
  const result = object ? JSON.stringify(object) : "";
  return lzstring.compressToUTF16(result);
};

/**
 * Get an object from the local storage by using a LZ-based compression algorithm.
 *
 * @param {string} object - The compressed object
 * @returns {object} The object
 */
const decompressObject = (object) => {
  if (object) {
    let decompressedObject = lzstring.decompressFromUTF16(object);
    return JSON.parse(decompressedObject);
  }
  return null;
};

const storage = createStorage({
  onLoad: decompressObject,
  onSave: compressObject,
});

const persistedState = storage.load(PERSIST_KEY) || {};

const rootReducer = combineReducers({
  forms: formsReducer,
});

const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(cdpMiddleware))
);

store.subscribe(
  throttle(() => {
    storage.save(PERSIST_KEY, store.getState());
  }, 1000)
);

export { store };
