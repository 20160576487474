/**
 * Safe chained function.
 *
 * @param {function} funcs - functions to chain
 * @returns {function|null}
 */
export const createChainedFunction = (...funcs) => {
  return funcs.reduce(
    (acc, func) => {
      if (typeof func !== "function") {
        return acc;
      }

      return function chainedFunction(...args) {
        acc.apply(this, args);
        func.apply(this, args);
      };
    },
    () => {}
  );
};

export const isUrlAllowedOrValid = (url) => {
  try {
    const parsedUrl = new URL(url);
    return (
      parsedUrl.protocol === "https:" &&
      (parsedUrl.hostname === "yomoni.fr" ||
        parsedUrl.hostname === "calendly.com" ||
        parsedUrl.hostname === "yomoni.typeform.com")
    );
  } catch (error) {
    console.warn(error.message);
    return false;
  }
};
