import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  boxPadding: {
    padding: theme.spacing(3),
  },
  choisirCreneau: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));
