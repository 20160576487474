import { breakpoints } from "./breakpoints";
import { palette } from "./palette";

const FONT_WEIGHT_LIGHT = 300;
const FONT_WEIGHT_REGULAR = 400;
const FONT_WEIGHT_MEDIUM = 500;
const FONT_WEIGHT_BOLD = 700;

const FONT_SIZE_HUGE = "2.8rem";
const FONT_SIZE_VERY_BIG = "2.5rem";
const FONT_SIZE_BIG = "2.2rem";
const FONT_SIZE_MEDIUM = "1.8rem";
const FONT_SIZE_NORMAL = "1.6rem";
const FONT_SIZE_SMALL = "1.4rem";
const FONT_SIZE_TINY = "1.2rem";
const FONT_SIZE_NANO = "1rem";

const h2 = {
  color: palette.text.primary,
  fontSize: "1.4rem",
  fontWeight: FONT_WEIGHT_REGULAR,
  letterSpacing: "0.1rem",
  lineHeight: "inherit",
  margin: "0 0 1.6rem",
  textTransform: "uppercase",
  [`@media only screen and (max-width: ${breakpoints.values.sm}px)`]: {
    fontSize: "1.2rem",
    marginBottom: "0.5rem",
  },
};

export const typography = {
  htmlFontSize: 10, // 10px simplification
  fontFamily: '"Fira Sans", sans-serif',
  fontSize: 16,
  fontWeightLight: FONT_WEIGHT_LIGHT,
  fontWeightRegular: FONT_WEIGHT_REGULAR,
  fontWeightMedium: FONT_WEIGHT_MEDIUM,
  fontWeightBold: FONT_WEIGHT_BOLD,
  h1: {
    color: palette.text.primary,
    fontFamily: '"Work Sans", sans-serif',
    fontSize: "2.4rem",
    letterSpacing: "0.06rem",
  },
  h2,
  h6: h2, // used by DialogTitle
  body1: {
    fontSize: "1.6rem",
  },
  body2: {
    fontSize: "1.4rem",
    lineHeight: 1.5,
  },
  fontSizeHuge: FONT_SIZE_HUGE,
  fontSizeBig: FONT_SIZE_BIG,
  fontSizeVeryBig: FONT_SIZE_VERY_BIG,
  fontSizeMedium: FONT_SIZE_MEDIUM,
  fontSizeNormal: FONT_SIZE_NORMAL,
  fontSizeSmall: FONT_SIZE_SMALL,
  fontSizeTiny: FONT_SIZE_TINY,
  fontSizeNano: FONT_SIZE_NANO,
};
