import PatternFormat from "react-number-format";

import { TextWidget } from "./TextWidget";

const NumberPatternFormat = (props) => {
  const { inputRef, format, displayType, ...other } = props;
  return (
    <PatternFormat
      {...other}
      getInputRef={inputRef}
      format={format}
      displayType={displayType}
    />
  );
};

export const NumberPatternWidget = (props) => {
  const { options = {}, ...otherProps } = props;

  const InputProps = options.InputProps || {};
  InputProps.inputComponent = NumberPatternFormat;

  return <TextWidget {...otherProps} options={{ ...options, InputProps }} />;
};
