/**
 * Extract options by using default values.
 */
const extractOptions = (options = {}) => {
  const { onLoad = (o) => o, onSave = (o) => o } = options;
  return { onLoad, onSave };
};

/**
 * Create a function that gets an object from the local storage matching the given key.
 *
 * Options:
 * - onLoad: a method that changes the object after being loaded
 *
 * @param {object} options - The options
 * @returns {object} The object
 */
const createLoad = (options) => (key) => {
  const { onLoad } = extractOptions(options);
  const obj = localStorage.getItem(key);
  return onLoad(obj);
};

/**
 * Put the object in the local storage with a given key.
 *
 * Options:
 * - onSave: a method that changes the object before being stored
 *
 * @param {object} options - The options
 */
const createSave = (options) => (key, object) => {
  const { onSave } = extractOptions(options);
  const changedObject = onSave(object);
  localStorage.setItem(key, changedObject);
};

/**
 * Remove an object from the local storage matching the given key.
 *
 * @param {string} key - The local storage key
 */
const reset = (key) => {
  localStorage.removeItem(key);
};

/**
 * Create an instance that manages objects in local storage.
 *
 * Options:
 * - onLoad: a method that changes the object after being loaded
 * - onSave: a method that changes the object before being stored
 *
 * @param {*} options
 */
const createStorage = (options) => {
  return {
    load: createLoad(options),
    reset,
    save: createSave(options),
  };
};

export { createStorage };
